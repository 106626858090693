import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { Divider, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
import { Body1, Body2, Header3, Header4, Header5, Subtitle1, A, Subtitle2 } from '../Components/TypographyComponents';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import uuid from 'react-uuid';

function FaqView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}

const Pic = styled('div')`
  background:url("/images/${props => props.image}") no-repeat;
  background-size:contain; 
  display:block;
`;

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const [value, setValue] = React.useState(0);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" >
      HOME
    </Link>,
    <Typography key="2" color="text.primary">
      FAQ
    </Typography>,
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let leftPanelWidth = lt.bunch12;
  let rightPanelWidth = lt.bunch4;
  if (lt.max === 12) {
    leftPanelWidth = lt.bunch8;
    rightPanelWidth = lt.bunch4;
  } else if (lt.max === 8) {
    leftPanelWidth = lt.bunch8;
    rightPanelWidth = lt.bunch8;
  } else if (lt.max === 4) {
    leftPanelWidth = lt.bunch4;
    rightPanelWidth = lt.bunch4;
  }

  let questions = {general: [
    {q:"What materials do you work with?", a:[<Row top={2} bottom={2}><Body2>10K, 14K, 18K Gold</Body2></Row>, <Row top={2} bottom={2}><Body2>Platinum</Body2></Row>]},
    {q:"How long does it take for me to receive the casting?", a:[<Row top={2} bottom={2}><Body2>Most items can be ready the following day.</Body2></Row>, <Row top={2} bottom={2}><Body2>Items that require assembly may take up to 2 weeks.</Body2></Row>, <Row top={2} bottom={2}><Body2>Some items may take up to 4 weeks.</Body2></Row>]},
    {q:"What is the pricing for molds?", a:[<Row top={2} bottom={2}><Body2>$30 and up, depending on the size of the item.</Body2></Row>]},
    {q:"Do you sell finished pieces?", a:[<Row top={2} bottom={2}><Body2>No, we only sell raw castings.</Body2></Row>]},
    {q:"How can I find out the status of my order?", a:[<Row top={2} bottom={2}><Body2>You may call or&nbsp;<A href="/contacts">e-mail</A>&nbsp;us.</Body2></Row>]},
    {q:"How can I place an order?", a:[<Row top={2} bottom={2}><Body2>You can place an order on this website, in our showroom, by phone or&nbsp;<A href="/contacts">e-mail</A></Body2></Row>]},
    {q:"What if I need extra help or an advice about my order?", a:[<Row top={2} bottom={2}><Body2>You may contact us with any question.</Body2></Row>]},
    {q:"How can I contact you?", a:[<Row top={2} bottom={2}><Body2>You can find our contact information&nbsp;<A href="/contacts">here</A></Body2></Row>]},
    {q:"Do your prices change daily?", a:[<Row top={2} bottom={2}><Body2>Yes, our prices change in accordance to the daily precious metal price.</Body2></Row>]},
    {q:"What is your return/exchange policy?", a:[<Row top={2} bottom={2}><Body2>You can find our return/exchange policy.</Body2></Row>]},
    {q:"Do you do repairs?", a:[<Row top={2} bottom={2}><Body2>No, we do not provide a repair service.</Body2></Row>]},
  ], design: [
    {q:"How long does it take to get a casting of my CAD Design?", a: [<Row top={2} bottom={2}><Body2>The whole process will take about 5-10 business days. Usually you will get your 3D render within 48 hours after you placed your order. Then we will work with you on adjustments if there are any. After you approve the design we will make a wax model for you to check. Usually it takes no more than 2 days. And if you approve the wax you can get your casting the next business day.</Body2></Row>]},
    {q:"Can I ask for changes to the design after seeing the render?", a: [<Row top={2} bottom={2}><Body2>We can make any necessary changes to the design you submitted. But if you would like to make significant adjustments you may be charged an extra fee.</Body2></Row>]},
    {q:"Why are the prongs so tall?", a: [<Row top={2} bottom={2}><Body2>We leave a little extra metal for cleaning, polishing, setting the stones and finishing the piece. When all of these steps are completed you will have a beautiful jewelry piece with tiny prongs.</Body2></Row>]},
    {q:"Can I see a wax model of the CAD design?", a: [<Row top={2} bottom={2}><Body2>Yes, we provide an opportunity to our customer to check the wax. But you need to be 100% satisfied with 3D rendering before it is printed.</Body2></Row>, <Row top={2} bottom={2}><Body2>If you will need changes to the design that will require reprinting of the wax you will be charged an extra fee.</Body2></Row>]},
    {q:"What's the price range to design my own custom piece?", a: [<Row top={2} bottom={2}><Body2>$59 and up, depending on the complexity of the design.</Body2></Row>]},
    {q:"How does it work? OR What are the steps of the design process?", a: [<Row top={2} bottom={2}><Body2>1. After you send us your design, in 48 hours you will get a rendering of your piece.</Body2></Row>, <Row top={2} bottom={2}><Body2>2. If you request changes, we will make them and will resend you another rendering for approval.</Body2></Row>, <Row top={2} bottom={2}><Body2>3. Upon your aproval we will snend your design to print. The wax will be ready within 48 hours.</Body2></Row>, <Row top={2} bottom={2}><Body2>4. Once you check and approve the wax, you will receive the casting the following day.</Body2></Row>]},
  ], print: [
    {q:"What type of file do you accept?", a:[<Row top={2} bottom={2}><Body2>We are making 3D wax print only for STL files.</Body2></Row>]},
    {q:"How do I save file as STL?", a:[<Row top={2} bottom={2}><Body2>In Matrix or Rhyno:</Body2></Row>, <Row top={2} bottom={2}><Body2>First select the entire object -&gt; make a mesh. Go to File -&gt; Export Selected -&gt; Choose STL as the file type.</Body2></Row>]},
    {q:"How long does it take to have my file casted?", a:[<Row top={2} bottom={2}><Body2>Usually we provide 3D wax for the next day and casting for the following day. But it also depends on how big your jewelry piece is.</Body2></Row>,<Row top={2} bottom={2}><Body2>For example, Hoops or Bangles might take one day longer.</Body2></Row>]},
    {q:"Can I only print a wax with you?", a:[<Row top={2} bottom={2}><Body2>Yes, you can. But the promotional price will not be applied to your order. You will have to pay a standard price of around $30. Depends on how big your jewelry piece is.</Body2></Row>]},
    {q:"Do I need to put supports on the model in my file?", a:[<Row top={2} bottom={2}><Body2>Not for all models. But if it is an eternity band or your design has lots of tiny parts you must put in supports. Otherwise your jewelry print can be damaged.</Body2></Row>]},
  ]}


  return(
    <Column width="100%" center>
      <Column width={lt.innerWidth} top={40} bottom={40}>
        <Column left={lt.max <= 8 ? 20: 40}>
          <Row cross={Axis.cross.center}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Row>
          <Row top={40}>
            <Header4 sx={{fontWeight:600, letterSpacing:3}}>FAQ</Header4>
          </Row>
          <Row>
            <Column width={leftPanelWidth}>
              <Column top={30} right={20}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="tab dialog">
                    <Tab label="GENERAL" {...a11yProps(0)} />
                    <Tab label="DESIGN" {...a11yProps(1)} />
                    <Tab label="PRINT" {...a11yProps(2)} />
                  </Tabs>
                </Box>
              </Column>
              <TabPanel value={value} index={0} sx={{padding:0}}>
                <Column main={Axis.main.start} top={20}>
                  { questions.general.map((v,i)=>{
                    return(
                      <Row key={uuid()} top={10} bottom={10} >
                        <Accordion sx={{width:"100%"}}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Header5 sx={{fontSize:18, fontWeight:600}}>{v.q}</Header5>
                          </AccordionSummary>
                          <AccordionDetails>
                            {v.a.map((k,i)=>{
                              return(
                                <Row key={uuid()}>{k}</Row>
                              );
                            })}
                            
                          </AccordionDetails>
                        </Accordion>
                      </Row>
                    );
                  })}
                </Column>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Column main={Axis.main.start} top={20} >
                  { questions.design.map((v,i)=>{
                    return(
                      <Row key={uuid()} top={10} bottom={10} >
                        <Accordion sx={{width:"100%"}}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Header5 sx={{fontSize:18, fontWeight:600}}>{v.q}</Header5>
                          </AccordionSummary>
                          <AccordionDetails>
                            {v.a.map((k,i)=>{
                              return(
                                <Row key={uuid()}>{k}</Row>
                              );
                            })}
                            
                          </AccordionDetails>
                        </Accordion>
                      </Row>
                    );
                  })}
                </Column>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Column main={Axis.main.start} top={20} >
                  { questions.print.map((v,i)=>{
                    return(
                      <Row key={uuid()} top={10} bottom={10} >
                        <Accordion sx={{width:"100%"}}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Header5 sx={{fontSize:18, fontWeight:600}}>{v.q}</Header5>
                          </AccordionSummary>
                          <AccordionDetails>
                            {v.a.map((k,i)=>{
                              return(
                                <Row key={uuid()}>{k}</Row>
                              );
                            })}
                            
                          </AccordionDetails>
                        </Accordion>
                      </Row>
                    );
                  })}
                </Column>
              </TabPanel>
            </Column>
            <div style={{display: lt.max > 8 ? 'block': 'none'}}>
              <Column width={rightPanelWidth} cross={Axis.cross.center} >
                <Column width="calc(100% - 40px)" top={50} >
                  <img src="/images/faq-bg.jpg?fit=contain&auto=format"></img>
                </Column>
              </Column>
            </div>
          </Row>
        </Column>
        </Column>
      </Column>
  );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant='div'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default FaqView16;