import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { Divider, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle } from './Components';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate, Link } from "react-router-dom";
import { activateNewCustomer, resetState } from '../redux/actions';
import { 
  ERR_TYPE_ACTIVATE_NEW_CUSTOMER,
} from "../redux/types";

var _ = require('lodash');

function ConfirmationView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainMenu>
          <Row main={Axis.main.between} width={lt.innerWidth}>
          </Row>
        </MainMenu>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}
 


function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errType;
  const customerActivateResponse = stateObj.customerActivateResponse;

  const query = new URLSearchParams(window.location.search);
  const email = query.get('email');
  const key = query.get('key');

  useEffect(()=>{
    dispatch(activateNewCustomer(email, key));

    return ()=>{
      
    }
  }, [])

  console.log(email, key);
  console.log(customerActivateResponse);
  console.log(errMessage);

  console.log(customerToken);

  /*if (customerToken !== "") {
    if (errMessage === "") {
      navigate(`/`);
    }
  }*/
  let alert = null
  if (errMessage !== "" && (errType === ERR_TYPE_ACTIVATE_NEW_CUSTOMER)) {
    alert = <Row><Alert severity='error' sx={{marginTop:"20px", marginBottom:"10px"}}><AlertTitle >{errType}</AlertTitle>{errMessage}</Alert></Row>;
  }
  let success = null;
  if (errMessage === "" && _.isEmpty(customerActivateResponse) === false ) {
    success = <Row><Alert severity='success' sx={{marginTop:"20px", marginBottom:"10px"}}><AlertTitle>Success</AlertTitle>Account is activated.</Alert></Row>;
  }

  return(
    <Column width="100%" center>
      <Column width={lt.innerWidth} top={40} cross={Axis.cross.center}>
        <Column>
          {alert}
          {success}
        </Column>
        { success && <Row left={20} right={20}>
          <Link to={"/login"} style={{textDecoration: "none"}}>
            <Button variant='contained' sx={{color:'white'}}>Log in</Button>
          </Link>
        </Row>}
      </Column>
    </Column>
  );
}

function MainMenu(props) {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;

  return(
    <Row>
        <HeaderNav width={window.innerWidth+"px"}>
          <Row width={lt.innerWidth}>
            {props.children}
          </Row>
        </HeaderNav>
    </Row>
  );
}


function ContactUsMenu() {

  const handleClick = (event) => {
    
  };

  return(
    <Typography>
      <HeaderMenuButton
        onClick={handleClick}
      >
        Contact Us
      </HeaderMenuButton>
    </Typography>
  );
}





export default ConfirmationView16;