import React, {useState, useEffect} from 'react';
import SpecialServiceDesignView16 from './View16/SpecialServiceDesign16';


function SpecialServiceDesignPage() {
  return (
    <SpecialServiceDesignView16 />
  );
}

export default SpecialServiceDesignPage;