import React, {useState, useEffect} from 'react';
import OrderInfoView16 from './View16/OrderInfo16';


function OrderInfoPage() {
  return (
    <OrderInfoView16 />
  );
}

export default OrderInfoPage;