import React, {useState, useEffect} from 'react';
import StoneCalcView16 from './View16/StoneCalc16';


function StoneCalcPage() {
  return (
    <StoneCalcView16 />
  );
}

export default StoneCalcPage;