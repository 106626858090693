import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { calc, Row, Column, Axis, Dbg } from '../layout'; 
import { Color } from '../flatColors';
import styled from 'styled-components'
import { CardHeader, Divider, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
//import { FormTitle, FormSubTitle, FormText } from './TypographyComponents';
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 


var _ = require('lodash');


export default function OrderSummary(props) {

  //console.log(props.cart);
  if (props.cart === undefined || _.isEmpty(props.cart)) {
    return null;
  }

  return(
    <Column width={props.width}>
      <Column left={props.left} right={props.right}>
      <Card variant="outlined" >
        <CardHeader title="Order summary"></CardHeader>
        <CardContent>
          <Row bottom={10}><Subtitle1>Products in Bag</Subtitle1></Row>
          <Divider></Divider>
          <Row top={10}></Row>
          { props.cart.items.map((v, i)=>{
            return(
              <React.Fragment key={"cartitem"+i}>
                <Row><Body2>{v.product.name} ({v.quantity} pc.)</Body2></Row>
              </React.Fragment>
            );
          })}
          <Row top={10}></Row>
          <Divider></Divider>
          <Column top={20} width="100%" cross={Axis.cross.end} >  
            <Row main={Axis.main.between} width="100%">
              <Row><Subtitle2>Subtotal excl. tax</Subtitle2></Row>
              <Row><Subtitle2>${props.cart.prices.subtotal_excluding_tax.value}</Subtitle2></Row>
            </Row>
            <Row main={Axis.main.between} width="100%">
              <Row><Subtitle2>Total</Subtitle2></Row>
              <Row><Subtitle2>${props.cart.prices.grand_total.value}</Subtitle2></Row>
            </Row>
          </Column>
        </CardContent>
      </Card>
      </Column>
    </Column>
  );
}