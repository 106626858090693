import React, {useState, useEffect} from 'react';
import SpecialService3dwaxView16 from './View16/SpecialService3dwax16';


function SpecialService3dwaxPage() {
  return (
    <SpecialService3dwaxView16 />
  );
}

export default SpecialService3dwaxPage;