import '../App.css';
import React, {useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { getProductInfo, addProductToCart, logOutMagento, setCartTotal, resetState, getCustomerDataFromGoogleSheet, getCustomerInfo } from "../redux/actions";
import { Row, Column, Stack, StackItem, Axis, Head, ContentContainer, Content, Filler, Footer, Header, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { Divider, Typography } from '@mui/material';
import { Phone, ShoppingBag, Search, ConstructionOutlined } from '@mui/icons-material';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle } from './Components';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import InputLabel from '@mui/material/InputLabel';
//import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { ERR_TYPE_CREATE_CART, ERR_TYPE_CUSTOMER_LOGIN, ERR_TYPE_ADD_PRODUCT_TO_CART } from "../redux/types";
import { getCartTotalProducts, isTokenValid } from '../ApiHelper';
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 
//import {Viewer} from '../viewer2';
import { Menu, MenuItem, MenuList } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import uuid from 'react-uuid';

var _ = require('lodash');

let options = {
  material: {
    option_id: 0, title:"", option_type_id:0 
  }, 
  style: {
    option_id: 0, title:"", option_type_id:0 
  },
  note: {
    option_id: 0, title: "", value:""
  },
  sales: {
    option_id: 0, title: "", value:""
  },
  customer: {
    option_id: 0, title: "", value:""
  },
  phone: {
    option_id: 0, title: "", value:""
  },
  account: {
    option_id: 0, title: "", value:""
  },
  qty: 1,
};


function ProductView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer>
        </Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}

 
const ImageGalleryWrapper = styled(Row)`
  overflow:hidden;
  border-width:0.2px;
  border-color:rgba(0,0,0,0);
  border-style:solid;
  border-radius:5px;
  &:hover {
    border-width:0.2px;
    border-color:black;
    border-style:solid;
    border-radius:5px;
  }
`;

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const productInfo = stateObj.productInfo;
  const customerDataFromGoogleSheet = stateObj.customerDataFromGoogleSheet;
  const customerToken = stateObj.customerToken;
  const customerInfo = stateObj.customerInfo;
  const GROUP_SALES_PERSON = stateObj.customer_group_sales_person;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errType;
  const cart_id = stateObj.cart_id;

  const [err, setError] = React.useState("");

  const query = new URLSearchParams(window.location.search);
  const sku = decodeURI(query.get('sku'));
  const uuid = query.get('uuid');
  const [ selectedImage, selectImage ] = useState('');
  const [ customersData, setCustomersData ] = useState([]);

  const [ loaded, setLoaded ] = useState(false);

  const customerRef = React.useRef();
  const accountRef = React.useRef();
  const phoneRef = React.useRef();
  const notesRef = React.useRef();

  let attr_3d_model = "";

  let materials = [];
  let styles = [];
  /*let sales_managers = [
    {option_type_id:1, title:"Adriana"},
    {option_type_id:2, title:"Allen"},
    {option_type_id:3, title:"Semuna"},
    {option_type_id:4, title:"Albert"},
    {option_type_id:5, title:"Tamara"},
    {option_type_id:5, title:"Ishani"},
  ];*/

  //console.log(customerInfo);
  //console.log(customerDataFromGoogleSheet);

  if (productInfo !== undefined && productInfo.hasOwnProperty("attr_3d_model")) {
    attr_3d_model = productInfo.attr_3d_model;
  }

  useEffect(()=>{
    options = {
      material: {
        option_id: 0, title:"", option_type_id:0 
      }, 
      style: {
        option_id: 0, title:"", option_type_id:0 
      },
      note: {
        option_id: 0, title: "", value:""
      },
      customer: {
        option_id: 0, title: "", value:""
      },
      phone: {
        option_id: 0, title: "", value:""
      },
      account: {
        option_id: 0, title: "", value:""
      },
      sales: {
        option_id: 0, title: "", value:""
      },
      qty: 1,
    };
    
    dispatch(getCustomerInfo(customerToken));
    dispatch(getProductInfo(sku));


    if (errMessage !== "") {
      //alert = <Row><Alert severity='error' sx={{marginTop:"20px", marginBottom:"10px"}}><AlertTitle>{errType}</AlertTitle>{errMessage}</Alert></Row>;
      //setError(errMessage);
      //dispatch(resetState({errMessage: "", errType:"",}));
    }

    return(()=>{
      if (customerRef.current) {
        customerRef.current.value = "";
      }
      if (accountRef.current) {
        accountRef.current.value = "";
      }
      if (phoneRef.current) {
        phoneRef.current.value = "";
      }
      if (notesRef.current) {
        notesRef.current.value = "";
      }
      selectImage("");
    });
  }, [uuid]);

  if (!_.isEmpty(customerInfo)) {
    options.sales.value = customerInfo.firstname;
  } 

  //console.log(productInfo);

  let name = "";
  let descriptionText = "";
  let skuText = "";
  
  try {
    name = productInfo.name;
    descriptionText = productInfo.description.html;
    skuText = productInfo.sku;
    for(let opt of productInfo.options) {
      if (opt.title === 'Material') {
        materials = opt.value;
      } else if (opt.title === 'Notes') {
        
      } else if (opt.__typename === 'CustomizableDropDownOption' && opt.title !== 'Material') {
        if (Array.isArray(opt.value)) {
          styles = opt.value;
        }
      }
    }
    //console.log(materials);
    //console.log(productInfo);
    if (_.isEmpty(productInfo) === false) {
      productInfo.options.map((v,i)=>{
        if (v.title === "Material") {
          options.material.option_id = v.option_id;
          options.material.title = v.title;
        } else if (v.title.includes("-")) {
          options.style.option_id = v.option_id;
          options.style.title = v.title;
        } else if (v.title === "Notes") {
          options.note.option_id = v.option_id;
          options.note.title = v.title;
        } else if (v.title === "Customer") {
          options.customer.option_id = v.option_id;
          options.customer.title = v.title;
        } else if (v.title === "Phone") {
          options.phone.option_id = v.option_id;
          options.phone.title = v.title;
        } else if (v.title === "Account") {
          options.account.option_id = v.option_id;
          options.account.title = v.title;
        } else if (v.title === "Sales") {
          options.sales.option_id = v.option_id;
          options.sales.title = v.title;
        }
      });
    }
    //console.log(options);
    //options.material.option_id = productInfo.options[0].option_id;
    //options.material.title = productInfo.options[0].title;
    //options.style.option_id = productInfo.options[1].option_id;
    //options.style.title = productInfo.options[1].title;
    //console.log(options.style.title);
    /*if (options.style.title.includes("- CP $ - FP $")) {
      options.style.title = options.style.title.substring(0, options.style.title.length - " - CP $ - FP $".length);
    }*/
    //options.note.option_id = productInfo.options[2].option_id;
    //options.note.title = productInfo.options[2].title;
  } catch(e) {
    //console.log(e);
  }

  let panelWidth = lt.bunch4;
  if (lt.max === 8) {
    panelWidth = lt.bunch4;
  } else if (lt.max === 12) {
    panelWidth = lt.bunch6;
  } else if (lt.max === 16) {
    panelWidth = lt.bunch8;
  }

  const addToBagHandler = ()=>{
    //console.log(customerInfo);
    
    if (parseInt(options.qty) > 0) {
      setError("");
    } else {
      setError("Invalid qty value.");
      return;
    }
    if (options.material.option_type_id === 0 || options.material.option_type_id === "") {
      setError("Material is required.");
      return;
    } else {
      setError("");
    }
    if (options.style.option_type_id === 0 || options.style.option_type_id === "") {
      setError("Style is required.");
      return;
    } else {
      setError("");
    }
    if (!options.note.value) {
      setError("Notes are required.");
      return;
    } else {
      setError("");
    }
    if (!options.account.value) {
      setError("Account is required.");
      return;
    } else {
      setError("");
    }
    if (customerInfo && customerInfo.hasOwnProperty('group_id') && customerInfo.group_id === GROUP_SALES_PERSON) {
      if (!options.customer.value) {
        setError("Customer is required.");
        return;
      } else {
        setError("");
      }
      if (!options.phone.value) {
        setError("Phone is required.");
        return;
      } else {
        setError("");
      }
    }
    if (options.note.value.length > 350 ) {
      setError("Notes have to have not more than 350 characters.");
      return;
    } else {
      setError("");
    }
    //options.note.value = options.note.value.replace("\n", "; ");
    const product = { sku: sku, qty: options.qty, custOptions: []};
    product.custOptions.push({id: options.material.option_id, value_string: String(options.material.option_type_id)});
    product.custOptions.push({id: options.style.option_id, value_string: String(options.style.option_type_id)});
    product.custOptions.push({id: options.note.option_id, value_string: options.note.value});
    product.custOptions.push({id: options.customer.option_id, value_string: options.customer.value});
    product.custOptions.push({id: options.phone.option_id, value_string: options.phone.value});
    product.custOptions.push({id: options.account.option_id, value_string: options.account.value});
    product.custOptions.push({id: options.sales.option_id, value_string: options.sales.value});
    console.log(product);
    dispatch(addProductToCart(product, customerToken));
    dispatch(resetState({currentProduct:product}));
    navigate("/cart");
  }

  const onChangeNotesHandler = (event)=>{
    options.note.value = event.target.value;
  }

  const onChangeCustomerHandler = (event)=>{
    options.customer.value = event.target.value.trim();
    let cust_data = [];
    if (options.customer.value.length >= 2) {
      customerDataFromGoogleSheet.map((v,i)=>{
        if (i > 0) {
          if (v[0].toUpperCase().includes(options.customer.value.toUpperCase())) {
            cust_data.push(v);
          }
        }
      });
    }
    if (options.customer.value === "") {
      cust_data = [];
    }
    setCustomersData(cust_data);
  }

  const onChangePhoneHandler = (event)=>{
    options.phone.value = event.target.value;
  }

  const onChangeAccountHandler = (event)=>{
    options.account.value = event.target.value;
  }

  const onChangeQtyHandler = (event)=>{
    options.qty = event.target.value;
  }

  let alert = <Row></Row>;
  if (err !== "") {
    alert = <Row><Alert severity='error' sx={{marginTop:"20px", marginBottom:"10px"}}>{err}</Alert></Row>;
  }

  if (errMessage !== "") {
    if (errType === ERR_TYPE_CREATE_CART) {
      console.log(errMessage);
      if (errMessage.includes("allowed for logged in")) {
        navigate("/login");
      }
    }
  }

  let leftPanelWidth = lt.bunch4-10;
  let rightPanelWidth = lt.bunch12;
  if (lt.max === 12) {
    rightPanelWidth = lt.bunch8;
  } else if (lt.max === 8) {
    rightPanelWidth = lt.bunch4;
  } else if (lt.max === 4) {
    leftPanelWidth = lt.bunch4-20;
    rightPanelWidth = lt.bunch4-20;
  }

  const imageClickHandler = (event)=>{
    const url = (event.currentTarget.dataset.id);
    //console.log(url);
    selectImage(url);
  }

  const view3DHandler = (event)=>{
    const sku = event.currentTarget.dataset.sku;
    console.log(sku);
    navigate(`/view3d?sku=${sku}`);
  }

  const customerDataMenuClose = ()=>{
    setCustomersData([]);
  }

  const customerDataMenuSelect = (event)=>{
    try {
      const v = JSON.parse(event.currentTarget.dataset.data);
      
      if (Array.isArray(v) && v.length === 3) {
        customerRef.current.value = v[0];
        phoneRef.current.value = v[1];
        accountRef.current.value = v[2];
        options.phone.value = phoneRef.current.value;
        options.customer.value = customerRef.current.value;
        options.account.value = accountRef.current.value;
        //console.log(options);
        setCustomersData([]);
      }
    } catch(e) {
      console.log(e);
    }
  } 

  let attr3DModelButton = null;
  if (productInfo !== undefined && productInfo.hasOwnProperty('attr_3d_model')) {
    if (productInfo.attr_3d_model) {
      attr3DModelButton = <Row top={20} bottom={10}><Button variant='contained' sx={{color:'white'}} data-sku={productInfo.sku} onClick={view3DHandler}>3D View</Button></Row>;
    } 
  }

  let gallery = null;
  if (attr_3d_model && sku) {
    let p = [...productInfo.media_gallery];
    p.unshift({url:"3d"});
    gallery = 
      <Column>
        { selectedImage === "" || selectedImage === "3d" ? 
        <Row height={leftPanelWidth} width={leftPanelWidth}>
          <iframe src={`/view3d?sku=${sku}`} height="100%" width="100%" style={{border:0}}></iframe>
        </Row>
        : 
        <Row>
          <img style={{objectFit:"contain", width:leftPanelWidth, maxHeight:leftPanelWidth}} src={ selectedImage ? selectedImage : productInfo.image.url} ></img>
        </Row>
        }
        <ImageList sx={{ width: "100%" }} cols={5} >
          {p.map((item, i) => {
            return (<ImageGalleryWrapper key={item.url} top={10} bottom={10} left={10} right={10} style={ selectedImage === item.url ? {borderStyle:'solid', borderWidth:1,boxSizing:'border-box', borderRadius:5, borderColor: Color.mainColor} : {}}><ImageListItem key={"img"+i} data-id={item.url} onClick={imageClickHandler}>
              { item.url === "3d" ?
              <img src="/images/3dview.png" width={200} height={200}></img>
              :
              <img
                src={ `${item.url}?w=200&h=20&fit=crop&auto=format`}
                alt={item.label}
                loading="lazy"
              />
              }
            </ImageListItem></ImageGalleryWrapper >
            )
            })}
        </ImageList>
      </Column>
  } else {
    if (selectedImage) {
      gallery = 
      <Column>
        <Row>
          <img style={{objectFit:"contain", width:leftPanelWidth, maxHeight:leftPanelWidth}} src={ selectedImage ? selectedImage : productInfo.image.url}></img>
        </Row>
        <ImageList sx={{ width: "100%" }} cols={5} >
          {productInfo.media_gallery.map((item, i) => {
            return (<ImageGalleryWrapper key={item.url} top={10} bottom={10} left={10} right={10} style={ selectedImage === item.url ? {borderStyle:'solid', borderWidth:1,boxSizing:'border-box', borderRadius:5, borderColor: Color.mainColor} : {}}><ImageListItem key={"img"+i} data-id={item.url} onClick={imageClickHandler}>
              <img
                src={ `${item.url}?w=200&h=20&fit=crop&auto=format`}
                alt={item.label}
                loading="lazy"
              />
            </ImageListItem></ImageGalleryWrapper >
            )
            })}
        </ImageList>
      </Column>
    } else {
      if (_.isEmpty(productInfo) === false) {
        gallery = 
        <Column>
          <Row>
            <img style={{objectFit:"contain", width:leftPanelWidth, maxHeight:leftPanelWidth}} src={ selectedImage ? selectedImage : productInfo.image.url}></img>
          </Row>
          <ImageList sx={{ width: "100%" }} cols={5} >
            {productInfo.media_gallery.map((item, i) => {
              return (<ImageGalleryWrapper key={item.url} top={10} bottom={10} left={10} right={10} style={ selectedImage === item.url ? {borderStyle:'solid', borderWidth:1,boxSizing:'border-box', borderRadius:5, borderColor: Color.mainColor} : {}}><ImageListItem key={"img"+i} data-id={item.url} onClick={imageClickHandler}>
                <img
                  src={ `${item.url}?w=200&h=20&fit=crop&auto=format`}
                  alt={item.label}
                  loading="lazy"
                />
              </ImageListItem></ImageGalleryWrapper >
              )
              })}
          </ImageList>
        </Column>
      }
    }
  }

  let details = 
  <Column width={rightPanelWidth}>
    <Column top={0} bottom={20} left={lt.max === 4 ? 0: 20} right={lt.max === 4 ? 0: 20}>
      <Header4>{name}</Header4>
      <Row top={2}><Caption>sku:&nbsp;</Caption><Caption>{skuText}</Caption></Row>
      <Row top={20}><Subtitle2>Description</Subtitle2></Row>
      <Row top={2}><Body1 dangerouslySetInnerHTML={{ __html: descriptionText }}/></Row>
      {attr3DModelButton}
      {/*<Row top={15}><Subtitle2>Sales Person *</Subtitle2></Row>
      <Row top={5}><SalesManagers variants={sales_managers}></SalesManagers></Row>*/}
      { customerInfo && customerInfo.hasOwnProperty('group_id') && customerInfo.group_id === GROUP_SALES_PERSON &&
      <>
      <Row top={20}><Subtitle2>Customer *</Subtitle2></Row>
      <Row top={5} width='100%'>
        <TextField fullWidth onChange={onChangeCustomerHandler} inputRef={customerRef} ></TextField>
      </Row>
      <Column left={0} top={0} cross={Axis.cross.start}>
        <Stack>
          <StackItem z={100} color="rgb(250,236,236)" sx={{ boxSizing:'border-box', borderRadius:2, borderColor:"#000", borderStyle:"solid", borderWidth: customersData.length > 0 ? 1: 0}}>
            <MenuList >
              { customersData.map((v,i)=>{
                if (i === 0) {
                  return (
                    <MenuItem key={v[0]+i} data-data={JSON.stringify(v)} onClick={customerDataMenuSelect}>  
                      <Row top={15}><Row right={10}></Row><Subtitle2>{v[0]}</Subtitle2></Row>
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={v[0]+i} data-data={JSON.stringify(v)} onClick={customerDataMenuSelect}>
                      <Row right={10}></Row><Subtitle2>{v[0]}</Subtitle2>
                    </MenuItem>
                  );
                }
                })
              }
            </MenuList>
          </StackItem>
          <StackItem z={101} top={-5} left={-5}>
            { customersData.length > 0 && 
              <Row>
                <IconButton color="text" aria-label="close" component="span" onClick={customerDataMenuClose}>
                  <CloseIcon />
                </IconButton>
              </Row>
            }
          </StackItem>
        </Stack>
      </Column>
      <Row top={20}><Subtitle2>Phone *</Subtitle2></Row>
      <Row top={5} width='100%'>
        <TextField fullWidth onChange={onChangePhoneHandler} inputRef={phoneRef}></TextField>
      </Row>
      </>
      }
      <Row top={20}><Subtitle2>Account # *</Subtitle2></Row>
      {customerInfo && customerInfo.hasOwnProperty('group_id') && customerInfo.group_id !== GROUP_SALES_PERSON &&
        <Row top={2}><Caption>If you don't have and account number please call to the store.</Caption></Row>
      }
      <Row top={5} width='100%'>
        <TextField fullWidth onChange={onChangeAccountHandler} inputRef={accountRef}></TextField>
      </Row>
      <Row top={15}><Subtitle2>Material *</Subtitle2></Row>
      <Row top={5}><Materials variants={materials}></Materials></Row>
      <Row top={20}><Subtitle2>{options.style.title} *</Subtitle2></Row>
      <Row top={5}><Styles variants={styles}></Styles></Row>
      <Row top={20}><Subtitle2>Notes *</Subtitle2></Row>
      <Row top={5} width='100%'>
        <TextField fullWidth multiline maxRows={3} onChange={onChangeNotesHandler} inputRef={notesRef} placeholder='head size, finger size, number of stones, etc.'></TextField>
      </Row>
      <Row top={5} width='100%'><Caption>Maximun 350 characters</Caption></Row>
      <Row top={20}><Subtitle2>Qty</Subtitle2></Row>
      <Row top={5} bottom={20}><TextField type="number" sx={{width:"75px"}} defaultValue={1} onChange={onChangeQtyHandler} inputProps={{min: 0, style: { textAlign: 'center' }}}></TextField></Row>
      {alert}
      <Row top={15}></Row>
      <Divider></Divider>
      <Row top={20} bottom={20}><Button size='large' variant='contained' sx={{width:"150px", color:'#ffffff'}} onClick={addToBagHandler}>Add to Bag</Button></Row>
    </Column>
  </Column>;

  return(
    <Column width="100%" center >
      {(productInfo && productInfo.hasOwnProperty("categories") && productInfo.categories.length > 0 & _.first(productInfo.categories).name === "Custom Orders" && customerInfo && customerInfo.hasOwnProperty('group_id') && customerInfo.group_id === GROUP_SALES_PERSON && customerToken) ?
        <Column width={lt.innerWidth} top={lt.max === 4 ? 15 : 40} cross={Axis.cross.center}>
          { _.isEmpty(productInfo) === false &&
          <>
          { lt.max > 4 ?
            <Row width="100%" main={Axis.main.between} >
              <Column width={leftPanelWidth}>
                {gallery}
              </Column>
              {details}
            </Row>
            :
            <Column >
              <Column width={leftPanelWidth}>
                {gallery}
              </Column>
              <Divider></Divider>
              <Row top={20}></Row>
              {details}
            </Column>
          }
          </>
          }
        </Column>
      :
        <>
          {productInfo && productInfo.hasOwnProperty("categories") && productInfo.categories.length > 0 & _.first(productInfo.categories).name !== "Custom Orders" ?
            <Column width={lt.innerWidth} top={lt.max === 4 ? 15 : 40} cross={Axis.cross.center}>
              { _.isEmpty(productInfo) === false &&
              <>
              { lt.max > 4 ?
                <Row width="100%" main={Axis.main.between} >
                  <Column width={leftPanelWidth}>
                    {gallery}
                  </Column>
                  {details}
                </Row>
                :
                <Column >
                  <Column width={leftPanelWidth}>
                    {gallery}
                  </Column>
                  <Divider></Divider>
                  <Row top={20}></Row>
                  {details}
                </Column>
              }
              </>
              }
            </Column>
            :
            <Column></Column>
          }
        </>
      }
      
    </Column>
  );
}


function Materials(props) {
  const [value, setValue] = React.useState('');

  let ids = [];
  props.variants.map((v,i)=>{
    ids.push(v.option_type_id);
  });
  if (value !== "") {
    if (!ids.includes(value)) {
      setValue("");
    }
  }

  const handleChange = (event) => {
    options.material.option_type_id = event.target.value;
    setValue(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ minWidth: 120 }}>
        <Select
          value={value}
          displayEmpty
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {props.variants.map((v, i)=>{
            return <MenuItem key={i} value={v.option_type_id}>{v.title}</MenuItem>
          })}
        </Select>
      </FormControl>
    </div>
  );
}

function Styles(props) {
  const [value, setValue] = React.useState('');

  let ids = [];
  props.variants.map((v,i)=>{
    ids.push(v.option_type_id);
  });
  if (value !== "") {
    if (!ids.includes(value)) {
      setValue("");
    }
  }

  const handleChange = (event) => {
    options.style.option_type_id = event.target.value;
    setValue(event.target.value);
  };

  //console.log(props.variants);

  return (
    <div>
      <FormControl sx={{ minWidth: 120 }}>
        <Select
          value={value}
          displayEmpty
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {props.variants.map((v, i)=>{
            return <MenuItem key={i} value={v.option_type_id}>{v.title}</MenuItem>
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default ProductView16;