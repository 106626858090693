import React, {useState, useEffect} from 'react';
import SearchView16 from './View16/Search16';


function SearchPage() {
  return (
    <SearchView16 />
  );
}

export default SearchPage;