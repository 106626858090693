import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { logInMagento, logOutMagento, clearAllErrors, createCustomer, resetState, requestPasswordResetEmail } from "../redux/actions";
import { calc, Row, Column, Axis, ContentContainer, Head, Content, Filler, Footer, Header, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { createChainedFunction, Divider, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle } from './Components';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { BrowserRouter, Routes, Route, Link, useNavigate } from "react-router-dom";
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 
import { ERR_TYPE_CREATE_CART, ERR_TYPE_CREATE_CUSTOMER, ERR_TYPE_CUSTOMER_LOGIN } from "../redux/types";

function LoginView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer>
          <Column width="100%" center='true'>
            <Header6>Footer</Header6>
          </Column>
        </Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const [value, setValue] = React.useState(0);
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errType;
  const customerCreated = stateObj.customerCreated;
  const [credentials, setCredentials] = React.useState({email:"", password:""});
  const [newCustomerRegData, setNewCustomerRegData] = React.useState({firstname:"", lastname:"", email:"", password:"", confirmpassword:""});
  const [successMessage, setSuccessMessage] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const requestPasswordResetEmailResult = stateObj.requestPasswordResetEmailResult;

  useEffect(()=>{
    if (customerToken !== "") {
      if (errMessage === "") {
        navigate(`/`);
      }
    }
    if (customerCreated) {
      setValue(0);
      setSuccessMessage("Thank you for registering with JR Castings & Findings Online Store. You must confirm your account. Please check your email for the confirmation link.");
      dispatch(resetState({customerCreated:"", errType: "", errMessage: ""}));
    }
    return(()=>{
      dispatch(resetState({requestPasswordResetEmailResult:false}));
    });
  }, [customerToken, customerCreated, errMessage, errType]);

  let alert = null;
  let alertWidth = lt.bunch8;
  if (lt.max === 4) {
    alertWidth = lt.bunch4;
  }
  if (errMessage) {
    let e = errMessage;
    if (errMessage.includes("reading 'token'")) {
      e = "Invalid email or password."
    }
    alert = <Row width={alertWidth} center>
      <Alert severity='error' sx={{marginTop:"20px", marginBottom:"10px"}} onClose={() => {
        dispatch(resetState({errType: "", errMessage: "", requestPasswordResetEmailResult:false}));
      }}><AlertTitle>{errType}</AlertTitle>{e}
      </Alert></Row>;
  } 

  if (requestPasswordResetEmailResult) {
    alert = <Row width={alertWidth} center>
      <Alert severity='success' sx={{marginTop:"20px", marginBottom:"10px"}} onClose={() => {
        dispatch(resetState({errType: "", errMessage: "", requestPasswordResetEmailResult:false}));
        }}><AlertTitle>SUCCESS</AlertTitle>
        If there is an account associated with {resetEmail} you will receive an email with a link to reset your password.
      </Alert></Row>;
  }

  //let success = null;
  if (successMessage) {
    alert = <Row width={alertWidth} center>
      <Alert severity='success' sx={{marginTop:"20px", marginBottom:"10px"}} onClose={() => {
        setSuccessMessage("");
        dispatch(resetState({customerCreated:"", errType: "", errMessage: "", requestPasswordResetEmailResult:false}));
      }}><AlertTitle>SUCCESS</AlertTitle>{successMessage}
      </Alert></Row>;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLogin = () => {
    dispatch(logInMagento(credentials.email, credentials.password));
  }

  const newCustomerFirstNameHandler = (event) => {
    setNewCustomerRegData({...newCustomerRegData, firstname: event.target.value});
  };

  const newCustomerLastNameHandler = (event) => {
    setNewCustomerRegData({...newCustomerRegData, lastname: event.target.value});
  };

  const newCustomerEmailHandler = (event) => {
    setNewCustomerRegData({...newCustomerRegData, email: event.target.value});
  };

  const newCustomerPasswordHandler = (event) => {
    setNewCustomerRegData({...newCustomerRegData, password: event.target.value});
  };

  /*const newCustomerConfirmPasswordHandler = (event) => {
    setNewCustomerRegData({...newCustomerRegData, confirmpassword: event.target.value});
  };*/

  const createCustomerHandler = () => {
    let payload = {...newCustomerRegData};
    delete payload.confirmpassword;
    console.log(payload);
    dispatch(createCustomer(payload));
  }

  const logInEmailHandler = (event) => {
    setCredentials({...credentials, email: event.target.value});
  };

  const logInPasswordHandler = (event) => {
    setCredentials({...credentials, password: event.target.value});
  };

  const resetPasswordHandler = (event) => {
    setResetEmail(event.target.value);
  };

  const handleReset = (event) => {
    console.log("reset");
    if (resetEmail.trim()) {
      dispatch(requestPasswordResetEmail(resetEmail));
    }
  }

  let edgeShift = 20;
  if (lt.max === 4) {
    edgeShift = 10;
  }

  return(
    <Column width="100%" center>
      <div style={{display: lt.max >= 4 && lt.max <= 16 ? 'block': 'none'}}>
        <Column width={lt.innerWidth} top={40} bottom={80} cross={Axis.cross.center}>
          <Column left={edgeShift} right={edgeShift} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="Log-in dialog">
                <Tab label="Registered" {...a11yProps(0)} />
                <Tab label="New Customer" {...a11yProps(1)} />
                <Tab label="Restore" {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Column>
          {alert}
          <TabPanel value={value} index={0} sx={{padding:0}}>
            <Column main={Axis.main.start} top={20} >
              <Row top={10} bottom={30}><Header5>Customer Login</Header5></Row>
              <Row bottom={20}>
                <Subtitle1>If you have an account, log in with your email address. Otherwise press the tab New Customer.</Subtitle1>
              </Row>
              <TextField
                autoFocus
                margin="dense"
                id="loginemail"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                onChange={logInEmailHandler}
              />
              <TextField
                margin="dense"
                id="loginpassword"
                label="Password"
                type="password"
                fullWidth
                variant="standard"
                onChange={logInPasswordHandler}
              />
              <Row main={Axis.main.start} top={40} >
                <Button variant='contained' sx={{color:"#fff"}} onClick={handleLogin}>Log in</Button>
              </Row>
            </Column>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Column main={Axis.main.start} top={20} >
              <Row top={10} bottom={30}><Header5>Create New Customer Account</Header5></Row>
              <Row bottom={20}><Subtitle1>Creating an account has many benefits: check out faster, keep more than one address, track orders and more.</Subtitle1></Row>
              <TextField
                autoFocus
                margin="dense"
                id="firstname"
                label="First Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={newCustomerFirstNameHandler}
              />
              <TextField
                margin="dense"
                id="lastname"
                label="Last Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={newCustomerLastNameHandler}
              />
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                variant="standard"
                onChange={newCustomerEmailHandler}
              />
              <TextField
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
                variant="standard"
                onChange={newCustomerPasswordHandler}
              />
              {/*<TextField
                margin="dense"
                id="confirmpassword"
                label="Confirm Password"
                type="password"
                fullWidth
                variant="standard"
                onChange={newCustomerConfirmPasswordHandler}
              />*/}
              <Row width="100%" main={Axis.main.start} top={40}>
                <Button variant='contained' sx={{color:"#fff"}} onClick={createCustomerHandler}>Create an Account</Button>
              </Row>
            </Column>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Column main={Axis.main.start} top={20} >
              <Row top={10} bottom={30}><Header5>Forgot Your Password?</Header5></Row>
              <Row bottom={20}><Subtitle1>Please enter your email address below to receive a password reset link.</Subtitle1></Row>
              <TextField
                autoFocus
                margin="dense"
                id="forgotemail"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                onChange={resetPasswordHandler}
              />
              <Row main={Axis.main.start} top={40}>
                <Button variant='contained' sx={{color:"#fff"}} onClick={handleReset}>Reset my password</Button>
              </Row>
            </Column>
          </TabPanel>
        </Column>
      </div>
    </Column>
  );
}

/*function MainMenu(props) {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;

  return(
    <Row>
      <HeaderNav width={window.innerWidth+"px"}>
        <Row width={lt.innerWidth}>
          {props.children}
        </Row>
      </HeaderNav>
    </Row>
  );
}*/


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant='div'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/*function ContactUsMenu() {

  const handleClick = (event) => {
    
  };

  return(
    <HeaderMenuButton
        onClick={handleClick}
    >
        Contact Us
    </HeaderMenuButton>
  );
}*/


export default LoginView16;