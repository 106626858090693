
import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Color } from '../flatColors';
import { calc, Row, Column, Axis, Dbg } from '../layout'; 
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle, FormText } from '../View16/Components';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from "react-router-dom";
import { 
  createCustomerAddress,
  getCountries,
} from "../redux/actions";
import { isTokenValid } from '../ApiHelper';
import { 
  ERR_TYPE_SET_SHIPPING_ADDRESS, 
  ERR_TYPE_SET_BILLING_ADDRESS, 
  ERR_TYPE_GET_BRAINTREE_TOKEN, 
  ERR_TYPE_SET_NONCE_TOKEN, 
  ERR_TYPE_PLACE_ORDER,
  ERR_TYPE_CREATE_CUSTOMER_ADDRESS,
} from "../redux/types";
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 


var _ = require('lodash');


let addrJson = { defaultForBilling: true, defaultForShipping: true };

function NewAddressForm() {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [streetLine1, setStreetLine1] = React.useState('');
  const [streetLine2, setStreetLine2] = React.useState('');
  const [streetLine3, setStreetLine3] = React.useState('');
  const [city, setCity] = React.useState('');
  const [zipCode, setZipCode] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [defaultForShipping, setForShipping] = React.useState(true);
  const [defaultForBilling, setForBilling] = React.useState(true);

  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const customerToken = stateObj.customerToken;
  const cart_id = stateObj.cart_id;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errtype;
  const successMessage = stateObj.successMessage;

  let alert = <Row></Row>;
  if (errMessage !== "") {
    alert = <Row><Alert severity='error' sx={{marginTop:"20px", marginBottom:"10px"}}><AlertTitle>{errType}</AlertTitle>{errMessage}</Alert></Row>;
  }
  /*if (successMessage !== "") {
    //alert = <Row><Alert severity='success' sx={{marginTop:"20px", marginBottom:"10px"}}>{successMessage}</Alert></Row>;
    //navigate(-1);
  }*/

  

  const firstNameHandler = (event) => {
    setFirstName(event.target.value);
    addrJson.firstName = event.target.value;
  }

  const lastNameHandler = (event) => {
    setLastName(event.target.value);
    addrJson.lastName = event.target.value;
  }

  const companyHandler = (event) => {
    setCompany(event.target.value);
    addrJson.company = event.target.value;
  }

  const streetLine1Handler = (event) => {
    setStreetLine1(event.target.value);
    if (addrJson.hasOwnProperty('streetLine') === false) {
      addrJson.streetLine = ["", "", ""];
    } 
    addrJson.streetLine[0] = event.target.value;
  }

  const streetLine2Handler = (event) => {
    setStreetLine2(event.target.value);
    if (addrJson.hasOwnProperty('streetLine') === false) {
      addrJson.streetLine = ["", "", ""];
    }
    addrJson.streetLine[1] = event.target.value;
    
  }

  const streetLine3Handler = (event) => {
    setStreetLine3(event.target.value);
    if (addrJson.hasOwnProperty('streetLine') === false) {
      addrJson.streetLine = ["", "", ""];
    }
    addrJson.streetLine[2] = event.target.value;
  }

  const cityHandler = (event) => {
    setCity(event.target.value);
    addrJson.city = event.target.value;
  }

  const zipCodeHandler = (event) => {
    setZipCode(event.target.value);
    addrJson.zipCode = event.target.value;
  }

  const phoneHandler = (event) => {
    setPhone(event.target.value);
    addrJson.phone = event.target.value;
  }

  const setForShippingHandler = (event) => {
    setForShipping(event.target.checked);
    addrJson.defaultForShipping = event.target.checked;
  }

  const setForBillingHandler = (event) => {
    setForBilling(event.target.checked);
    addrJson.defaultForBilling = event.target.checked;
    //console.log(addrJson);
  }

  const saveHandler = () => {
    let address = {};
    address.firstname = addrJson.hasOwnProperty('firstName') ? '"'+addrJson.firstName+'"' : '""';
    address.lastname = addrJson.hasOwnProperty('lastName') ? '"'+addrJson.lastName+'"' : '""';
    address.company = addrJson.hasOwnProperty('company') ? '"'+addrJson.company+'"' : '""';
    let s = "";
    if (addrJson.hasOwnProperty("streetLine")) {
      for (let item of addrJson.streetLine) {
        s += `"${item}",`;
      }
      if (s.length > 1) {
        s = s.substring(0, s.length-1);
      }
    }
    address.street = s; 
    address.city = addrJson.hasOwnProperty('city') ? '"'+addrJson.city+'"' : '""';
    address.regionCode = addrJson.hasOwnProperty('regionCode') ? '"'+addrJson.regionCode+'"' : '""';
    address.regionId = addrJson.hasOwnProperty('regionId') ? addrJson.regionId : null;
    address.zipCode = addrJson.hasOwnProperty('zipCode') ? '"'+addrJson.zipCode+'"' : '""';
    address.countryCode = addrJson.hasOwnProperty('countryCode') ? addrJson.countryCode : '""';
    address.phone = addrJson.hasOwnProperty('phone') ? '"'+addrJson.phone+'"' : '""';
    address.default_billing = addrJson.hasOwnProperty('defaultForBilling') ? addrJson.defaultForBilling : true;
    address.default_shipping = addrJson.hasOwnProperty('defaultForShipping') ? addrJson.defaultForShipping : true;

    //console.log(address);

    /*setFirstName('');
    setLastName('');
    setCompany('');
    setStreetLine1('');
    setStreetLine2('');
    setStreetLine3('');
    setCity('');
    setZipCode('');
    setPhone('');
    setForShipping(true);
    setForBilling(true);*/

    dispatch(createCustomerAddress(customerToken, address));
    addrJson = { defaultForBilling: true, defaultForShipping: true };
    
    
  }

  return(
  <Column top={20} bottom={40}>
    <Row bottom={10}><Header6>New Address</Header6></Row>
    {alert}
    <Row top={5} bottom={5}><TextField size="small" placeholder='First Name *' onChange={firstNameHandler}>{firstName}</TextField></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='Last Name *' onChange={lastNameHandler}>{lastName}</TextField></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='Company' onChange={companyHandler}>{company}</TextField></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='Street Line 1 *' onChange={streetLine1Handler}>{streetLine1}</TextField></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='Street Line 2' onChange={streetLine2Handler}>{streetLine2}</TextField></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='Street Line 3' onChange={streetLine3Handler}>{streetLine3}</TextField></Row>
    <Row top={8} bottom={8}><CountrySelect></CountrySelect></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='City *' onChange={cityHandler}>{city}</TextField></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='Zip/Postal Code *' onChange={zipCodeHandler}>{zipCode}</TextField></Row>
    <Row top={5} bottom={10}><TextField size="small" placeholder='Phone Number *' onChange={phoneHandler}>{phone}</TextField></Row>
    <FormGroup>
      <FormControlLabel control={<Checkbox checked={defaultForShipping} onChange={setForShippingHandler}/>} label="Default for shipping" />
      <FormControlLabel control={<Checkbox checked={defaultForBilling} onChange={setForBillingHandler}/>} label="Default for billing" />
      <Row top={20}><Button sx={{color:'#fff', minWidth:120}} variant='contained' onClick={saveHandler}>Save</Button></Row>
  </FormGroup>
  </Column>
  );
}

function CountrySelect() {
  const [country, setCountry] = React.useState('');
  const [region, setRegion] = React.useState('');
  const [availRegions, setAvailRegions] = React.useState([]);
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const countries = stateObj.countries;

  /*let i = _.findIndex(regions, function(o) { return o.code == region; });
  console.log(i);*/

  useEffect(()=>{
    dispatch(getCountries());
  }, [JSON.stringify(countries)]);

  const handleChangeCountries = (event) => {
    addrJson.countryCode = event.target.value;
    addrJson.regionCode = "";
    addrJson.regionId = null;

    let rg = [];
    const i = _.findIndex(countries, function(o) { return o.id === event.target.value; });
    if (i >= 0) {
      if (countries[i].available_regions !== null) {
        rg = countries[i].available_regions;
      }
    } else {
      rg = [];
      addrJson.regionId = null;
    }
    if (event.target.value === "") {
      delete addrJson.countryCode;
      delete addrJson.regionCode;
    } 
    setCountry(event.target.value);
    setRegion('');
    setAvailRegions(rg);
  };

  const handleChangeRegions = (event) => {
    addrJson.regionCode = event.target.value;
    const i = _.findIndex(availRegions, function(o) { return o.code == event.target.value; });
    if (i !== -1) {
      addrJson.regionId = availRegions[i].id;
    } else {
      addrJson.regionId = null;
    }
    if (event.target.value === "") {
      delete addrJson.regionCode;
    }
    setRegion(event.target.value);
  };

  try {
    function compare( a, b ) {
      if ( a.full_name_english < b.full_name_english ){
        return -1;
      }
      if ( a.full_name_english > b.full_name_english ){
        return 1;
      }
      return 0;
    }
    countries.sort(compare);
    let i = _.findIndex(countries, function(o) { return o.id == 'US'; });
    const el = {...countries[i]};
    countries.splice(i,1)
    countries.unshift(el);
  } catch {}

  return (
    <Column>
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label1">Country *</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select1"
          value={country}
          label="Country *"
          onChange={handleChangeCountries}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          { 
          countries.map((v,i)=>{
            return (<MenuItem key={"country"+i} value={v.id}>{v.full_name_english}</MenuItem>);
          })
          }
        </Select>
      </FormControl>
      

      { availRegions.length > 0 && <Column top={10}><FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Region *</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={region}
          label="Region *"
          onChange={handleChangeRegions}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          { 
          availRegions.map((v,i)=>{
            return (<MenuItem key={"region"+i} value={v.code}>{v.name}</MenuItem>);
          })
          }
        </Select>
      </FormControl></Column>}
    </Box>
    </Column>
  );
}

export default NewAddressForm;