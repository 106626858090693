import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { calc, Row, Column, Axis, Dbg, ContentContainer, Head, Content, Filler, Footer, Header, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { CardHeader, Divider, Typography } from '@mui/material';
import { DataObjectRounded, Phone, PinDropSharp, VpnLock } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle, FormText } from './Components';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Accordion from '@mui/material/Accordion';
import Switch from '@mui/material/Switch';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import OrderSummary from '../Components/OrderSummary';
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 

import { 
  updateCalculatedLayout,
  logOutMagento, 
  getBraintreeToken, 
  placeOrder,
  setError,
  getCountries,
  getRegions,
  getAddresses,
  setAddressesOnCart,
  resetAddressesOnCart,
  deleteCustomerAddress,
  setCustomerAddressesAsDefalut,
  selectAddressId,
} from "../redux/actions";
import { isTokenValid } from '../ApiHelper';
import { 
  ERR_TYPE_SET_SHIPPING_ADDRESS, 
  ERR_TYPE_SET_BILLING_ADDRESS, 
  ERR_TYPE_GET_BRAINTREE_TOKEN, 
  ERR_TYPE_SET_NONCE_TOKEN, 
  ERR_TYPE_PLACE_ORDER,
  ERR_TYPE_DELETE_CUSTOMER_ADDRESS
} from "../redux/types";
import NewAddressForm from '../Components/NewAddressForm';

var _ = require('lodash');

let shippingAddressJson = {};
let billingAddressJson = {};

function CheckoutView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const cart = stateObj.cart;
  const lt = stateObj.lt;
  const store = useStore();

  /*useEffect(()=>{
    const update = ()=>{
      dispatch(updateCalculatedLayout(calc(window.innerWidth, 4)));
    }
    window.addEventListener("resize", update);

    return () => {
      window.removeEventListener("resize", update);
    };
  }, [lt.containerWidth])
  */

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer>
        </Footer>
      </ContentContainer>
    </ThemeProvider>

    
  );

}
 
/*
<ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer>
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainMenu>
          <Row main={Axis.main.end} width={lt.innerWidth}>
            <Row>
              <ContactUsMenu></ContactUsMenu>
            </Row>
          </Row>
        </MainMenu>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer>
          <Column width="100%" center='true'>
            <Header6>Footer</Header6>
          </Column>
        </Footer>
      </ContentContainer>
    </ThemeProvider>
*/

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();

  const [shipAndBillThenSameValue, setShipAndBillTheSame] = React.useState(true);

  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errType;
  const cart = stateObj.cart;
  const cart_id = stateObj.cart_id;
  const cartGrandTotal = stateObj.cartGrandTotal;
  const shippingAddress = stateObj.shippingAddress;
  const billingAddress = stateObj.billingAddress;
  const placedOrder = stateObj.placedOrder;
  const addresses = stateObj.addresses;
  const countries = stateObj.countries;

  let alert = <Row></Row>;
  if (errMessage !== "" && (
    errType === ERR_TYPE_SET_SHIPPING_ADDRESS || 
    errType === ERR_TYPE_SET_BILLING_ADDRESS ||  
    errType === ERR_TYPE_GET_BRAINTREE_TOKEN || 
    errType === ERR_TYPE_PLACE_ORDER ||
    errType === ERR_TYPE_DELETE_CUSTOMER_ADDRESS) ) {
    alert = <Row><Alert severity='error' sx={{marginTop:"20px", marginBottom:"10px"}}><AlertTitle>{errType}</AlertTitle>{errMessage}</Alert></Row>;
  }

  useEffect(()=>{
    if (customerToken !== "") {
      isTokenValid(customerToken, (result)=>{
        if (result) {
          //console.log(addresses);
          /*if (addresses.length === 0) {
            
            dispatch(getAddresses(customerToken));
          } */
        } else {
          dispatch(logOutMagento());
        }
      });
    }
    
    dispatch(getCountries());

    if (Object.keys(shippingAddress).length > 0 && Object.keys(billingAddress).length > 0) {
      navigate("/pay");
    }
  }, [
    countries.length,  
    JSON.stringify(shippingAddress),
    JSON.stringify(billingAddress),
    JSON.stringify(addresses),
    ]);



  const shipAndBillTheSameHandler = (event) => {
    setShipAndBillTheSame(event.target.checked);
  }

  const payHandler = (event) => {
    if (shipAndBillThenSameValue) {
      billingAddressJson = {...shippingAddressJson};
    }
    
    billingAddressJson.same_as_shipping = shipAndBillThenSameValue;
    
    let saddressJson = {};
    if (shippingAddressJson.hasOwnProperty('customer_address_id')) {
      saddressJson.customer_address_id = shippingAddressJson.customer_address_id;
    } else {
      saddressJson.firstName = shippingAddressJson.hasOwnProperty('firstName') ? '"'+shippingAddressJson.firstName+'"' : '""';
      saddressJson.lastName = shippingAddressJson.hasOwnProperty('lastName') ? '"'+shippingAddressJson.lastName+'"' : '""';
      saddressJson.company = shippingAddressJson.hasOwnProperty('company') ? '"'+shippingAddressJson.company+'"' : '""';
      let s = "";
      if (shippingAddressJson.hasOwnProperty("streetLine")) {
        for (let item of shippingAddressJson.streetLine) {
          s += `"${item}",`;
        }
        if (s.length > 1) {
          s = s.substring(0, s.length-1);
        }
      }
      saddressJson.street = s; 
      saddressJson.city = shippingAddressJson.hasOwnProperty('city') ? '"'+shippingAddressJson.city+'"' : '""';
      saddressJson.regionCode = shippingAddressJson.hasOwnProperty('regionCode') ? '"'+shippingAddressJson.regionCode+'"' : '""';
      saddressJson.zipCode = shippingAddressJson.hasOwnProperty('zipCode') ? '"'+shippingAddressJson.zipCode+'"' : '""';
      saddressJson.countryCode = shippingAddressJson.hasOwnProperty('countryCode') ? '"'+shippingAddressJson.countryCode+'"' : '""';
      saddressJson.phone = shippingAddressJson.hasOwnProperty('phone') ? '"'+shippingAddressJson.phone+'"' : '""';
      saddressJson.saveForFuture = shippingAddressJson.hasOwnProperty('saveForFuture') ? shippingAddressJson.saveForFuture : true;
      
      console.log(saddressJson);
      //dispatch(setShippingAddress(customerToken, cart_id, saddressJson));
    }

    let baddressJson = {};
    if (billingAddressJson.hasOwnProperty('customer_address_id')) {
      baddressJson.customer_address_id = billingAddressJson.customer_address_id;
    } else {
      baddressJson.firstName = billingAddressJson.hasOwnProperty('firstName') ? '"'+billingAddressJson.firstName+'"' : '""';
      baddressJson.lastName = billingAddressJson.hasOwnProperty('lastName') ? '"'+billingAddressJson.lastName+'"' : '""';
      baddressJson.company = billingAddressJson.hasOwnProperty('company') ? '"'+billingAddressJson.company+'"' : '""';
      let s = "";
      if (billingAddressJson.hasOwnProperty("streetLine")) {
        for (let item of billingAddressJson.streetLine) {
          s += `"${item}",`;
        }
        if (s.length > 1) {
          s = s.substring(0, s.length-1);
        }
      }
      baddressJson.street = s; 
      baddressJson.city = billingAddressJson.hasOwnProperty('city') ? '"'+billingAddressJson.city+'"' : '""';
      baddressJson.regionCode = billingAddressJson.hasOwnProperty('regionCode') ? '"'+billingAddressJson.regionCode+'"' : '""';
      baddressJson.zipCode = billingAddressJson.hasOwnProperty('zipCode') ? '"'+billingAddressJson.zipCode+'"' : '""';
      baddressJson.countryCode = billingAddressJson.hasOwnProperty('countryCode') ? '"'+billingAddressJson.countryCode+'"' : '""';
      baddressJson.phone = billingAddressJson.hasOwnProperty('phone') ? '"'+billingAddressJson.phone+'"' : '""';
      baddressJson.saveForFuture = billingAddressJson.hasOwnProperty('saveForFuture') ? billingAddressJson.saveForFuture : true;
      baddressJson.same_as_shipping = shipAndBillThenSameValue;
      
      console.log(baddressJson);
      //dispatch(setBillingAddress(customerToken, cart_id, baddressJson));
    }
    console.log(saddressJson, baddressJson);
    console.log(cart_id);
    dispatch(setAddressesOnCart(customerToken, cart_id, saddressJson, baddressJson));
    /*if (cart && cart.hasOwnProperty('prices') && cart.prices.grand_total.value === 0) {
      dispatch(placeOrder(customerToken, cart_id, "", "free"));
    }*/
  }

  let leftPanelWidth = lt.bunch12;
  let rightPanelWidth = lt.bunch4;
  if (lt.max === 12) {
    leftPanelWidth = lt.bunch9;
    rightPanelWidth = lt.bunch3;
  } else if (lt.max === 8) {
    leftPanelWidth = lt.bunch5;
    rightPanelWidth = lt.bunch3;
  } else if (lt.max === 4) {
    leftPanelWidth = lt.bunch4;
    rightPanelWidth = lt.bunch4;
  }

  let buttonNextCaption = "Next";
  if (cart && cart.hasOwnProperty('prices') && cart.prices.grand_total.value > 0) {
    buttonNextCaption = "Next";
  } else {
    buttonNextCaption = "Place order";
  }

  return(
    <Column width="100%" center='true'>
      <Row width={lt.innerWidth} top={40} main={Axis.main.between}>
        <div style={{display: 'flex', flexDirection: lt.max === 4 ? 'column' : 'row'}}>
          <Column width={leftPanelWidth} color={Color.flatTransparent} cross={Axis.cross.start} >
            <Column left={lt.max === 4 ? 10 : 20} right={lt.max === 4 ? 10 : 20}>
              <Header4>Shipping Address</Header4>
              { addresses.length === 0 ?
                <Column top={20}><AddressForm countries={countries} forShipping={true}></AddressForm></Column>
              :
                <Column top={20}><AddressBook forShipping={true} addresses={addresses} ></AddressBook></Column>
              }
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={shipAndBillThenSameValue} />} label="My billing and shipping address are the same" onChange={shipAndBillTheSameHandler} />
              </FormGroup>
              {shipAndBillThenSameValue ? 
                <></>
              : 
                <>
                  <Row top={20}></Row><Divider></Divider><Row bottom={30}></Row>
                  <Header4>Billing Address</Header4>
                  { addresses.length === 0 ?
                    <>
                      <AddressForm countries={countries} forShipping={false}></AddressForm>
                    </>
                  :
                  <Column top={20}><AddressBook forShipping={false} addresses={addresses} ></AddressBook></Column>
                  }
                </>
              }
            </Column>
          </Column>
          <Column bottom={lt.max === 4 ? 15: 20} top={lt.max === 4 ? 20: 0}>
            <OrderSummary cart={cart} width={rightPanelWidth} left={lt.max === 4 ? 10: 20} right={lt.max === 4 ? 10: 0}></OrderSummary>
            <Row main={Axis.main.end} top={20} left={lt.max === 4 ? 10: 20} right={lt.max === 4 ? 10: 0} bottom={60}>
              <Button variant='contained' size='large' sx={{color:"#fff", minWidth: "100%"}} onClick={payHandler}>{buttonNextCaption}</Button>
            </Row>
          </Column>
        </div>
      </Row>
      {alert}
    </Column>
  );
}

function AddressBook(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const selectedShippingAddressId = stateObj.selectedShippingAddressId;
  const selectedBillingAddressId = stateObj.selectedBillingAddressId
  let countries = stateObj.countries;

  const [stringAddress, setStringAddress] = useState('');

  let selectedAddressId = selectedShippingAddressId;
  if (!props.forShipping) {
    selectedAddressId = selectedBillingAddressId;
  }

  useEffect(()=>{
    let defaultAddress = null;
    let company = "";
    let street = "";
    let city = "";
    let region = "";
    let zipCode = "";
    let countryCode = "";
    let countryName = "";
    let phone = "";
    let name = "";

    if (selectedAddressId !== null) {
      const i = _.findIndex(props.addresses, function(o) { return o.id === parseInt(selectedAddressId); });
      if (i !== -1) {
        defaultAddress = props.addresses[i];
        
      }
    } else {
      if (props.forShipping) {
        shippingAddressJson.customer_address_id = null;
      } else {
        billingAddressJson.customer_address_id = null;
      }
      if (props.forShipping) {
        for (let addr of props.addresses) {
          if (addr.default_shipping) {
            defaultAddress = addr;
            shippingAddressJson.customer_address_id = parseInt(addr.id);
            dispatch(selectAddressId(addr.id, props.forShipping));
            break;
          }
        }
        //shippingAddressJson.customer_address_id = selectedAddressId;
      } else {
        for (let addr of props.addresses) {
          if (addr.default_billing) {
            defaultAddress = addr;
            billingAddressJson.customer_address_id = parseInt(addr.id);
            dispatch(selectAddressId(addr.id, props.forShipping));
            break;
          }
        }
        //billingAddressJson.customer_address_id = selectedAddressId;
      }
    }
  
    if (defaultAddress !== null) {
  
      if (defaultAddress.hasOwnProperty('firstname') && defaultAddress.hasOwnProperty('lastname')) {
        name = defaultAddress.firstname + " " + defaultAddress.lastname;
      }
      if (defaultAddress.hasOwnProperty('company')) {
        company = defaultAddress.company;
      }
      if (defaultAddress.hasOwnProperty('street')) {
        for (let item of defaultAddress.street) {
          street += item + ",";
        }
        if (street.length > 1) {
          street = street.substring(0, street.length-1);
        }
      }
      if (defaultAddress.hasOwnProperty('city')) {
        city = defaultAddress.city;
      }
      if (defaultAddress.hasOwnProperty('region')) {
        region = defaultAddress.region.region;
        if (region === null) {
          region = "";
        }
      }
      if (defaultAddress.hasOwnProperty('postcode')) {
        zipCode = defaultAddress.postcode;
      }
      if (defaultAddress.hasOwnProperty('country_code')) {
        countryCode = defaultAddress.country_code;
        let i = _.findIndex(countries, function(o) { return o.id === countryCode; });
        if (i != -1) {
          countryName = countries[i].full_name_english;
        }
      }
      if (defaultAddress.hasOwnProperty('telephone')) {
        phone = defaultAddress.telephone;
      }
    }
  
    let addressAsString = "";
    if (name) {
      addressAsString += name + ", ";
    }
    if (company) {
      addressAsString += company + ", ";
    }
    if (street) {
      addressAsString += street + ", ";
    }
    if (city) {
      addressAsString += city + ", ";
    }
    if (region) {
      addressAsString += region + ", ";
    }
    if (zipCode) {
      addressAsString += zipCode + ", ";
    }
    if (countryName) {
      addressAsString += countryName + ", ";
    }
    if (phone) {
      addressAsString += "tel.: "+phone;
    }
    setStringAddress(addressAsString);
  },[selectedAddressId]);
  
  
  return(
    <Column>
      { stringAddress !== "" ? 
        <Row><Body1>{stringAddress}</Body1></Row>
        : 
        <Subtitle2>Select address from below</Subtitle2>
      }
      <Row top={20} bottom={20}>
        <AddressBookAccordion addresses={props.addresses} countries={countries} forShipping={props.forShipping}></AddressBookAccordion>
      </Row>
    </Column>
  );
}

function AddressBookAccordion(props) {
  //const [newAddressFormShown, showNewFormAddress] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errType;
  const [checkNewAddress, setCheckNewAddress] = React.useState(false);
  const addresses = stateObj.addresses;

  useEffect(()=>{
    setCheckNewAddress(false);
  }, [JSON.stringify(addresses)]);

  const deleteAddress = (event) => {
    dispatch(deleteCustomerAddress(customerToken, event.currentTarget.dataset.id));
  }

  const setAsDefaultForShippingHandler = (event) => {
    const addressId = event.currentTarget.dataset.id;
    dispatch(setCustomerAddressesAsDefalut(customerToken, addressId, true, 0));
  }

  const setAsDefaultForBillingHandler = (event) => {
    const addressId = event.currentTarget.dataset.id;
    dispatch(setCustomerAddressesAsDefalut(customerToken, addressId,  true, 1));
  }

  const useThisAddressHandler = (event) => {
    const addressId = event.currentTarget.dataset.id;
    if (props.forShipping) {
      shippingAddressJson.customer_address_id = parseInt(addressId);
    } else {
      billingAddressJson.customer_address_id = parseInt(addressId);
    }
    dispatch(selectAddressId(addressId, props.forShipping))
  }

  let i = 0;
  let content = [];
  for (let addr of props.addresses) {
    i++;
    let company = "";
    let street = "";
    let city = "";
    let region = "";
    let zipCode = "";
    let countryCode = "";
    let countryName = "";
    let phone = "";
    let name = "";
    let addressAsString = "";
    if (addr.hasOwnProperty('firstname') && addr.hasOwnProperty('lastname')) {
      name = addr.firstname + " " + addr.lastname;
    }
    if (addr.hasOwnProperty('company')) {
      company = addr.company;
    }
    if (addr.hasOwnProperty('street')) {
      for (let item of addr.street) {
        street += item + ",";
      }
      if (street.length > 1) {
        street = street.substring(0, street.length-1);
      }
    }
    if (addr.hasOwnProperty('city')) {
      city = addr.city;
    }
    if (addr.hasOwnProperty('region')) {
      region = addr.region.region;
      if (region === null) {
        region = "";
      }
    }
    if (addr.hasOwnProperty('postcode')) {
      zipCode = addr.postcode;
    }
    if (addr.hasOwnProperty('country_code')) {
      countryCode = addr.country_code;
      let i = _.findIndex(props.countries, function(o) { return o.id === countryCode; });
      if (i != -1) {
        countryName = props.countries[i].full_name_english;
      }
    }
    if (addr.hasOwnProperty('telephone')) {
      phone = addr.telephone;
    }

    if (name) {
      addressAsString += name + ", ";
    }
    if (company) {
      addressAsString += company + ", ";
    }
    if (street) {
      addressAsString += street + ", ";
    }
    if (city) {
      addressAsString += city + ", ";
    }
    if (region) {
      addressAsString += region + ", ";
    }
    if (zipCode) {
      addressAsString += zipCode + ", ";
    }
    if (countryName) {
      addressAsString += countryName + ", ";
    }
    if (phone) {
      addressAsString += "tel.: "+phone;
    }

    let subtitle = "";
    if (addr.default_billing && addr.default_shipping) {
      subtitle = "(This address is default for shipping and billing)";
    } else if (addr.default_billing && !addr.default_shipping) {
      subtitle = "(This address is default for billing)";
    } else if (!addr.default_billing && addr.default_shipping) {
      subtitle = "(This address is default for shipping)";
    }

    let titleForUsingButton="Use This Address For Shipping";
    if (!props.forShipping) {
      titleForUsingButton="Use This Address For Billing"
    }

    content.push(
      <Column bottom={10} key={'item'+i}>
        <Row top={15} bottom={1}><Body2>{addressAsString}</Body2></Row>
        { subtitle !== "" && <Row top={2} bottom={2}><Caption>{subtitle}</Caption></Row>}
        { !addr.default_shipping && <Row top={10} bottom={5}><Button sx={{height:30, fontSize:12, fontWeight:400}} data-id={addr.id} onClick={setAsDefaultForShippingHandler}>Set As Default For Shipping</Button></Row>}
        { !addr.default_billing && <Row top={5} bottom={5}><Button sx={{height:30, fontSize:12, fontWeight:400}} data-id={addr.id} onClick={setAsDefaultForBillingHandler}>Set As Default For Billing</Button></Row>}
        <Row top={20} bottom={20} main={Axis.main.between}><Button variant='contained' size='small' sx={{color: '#fff', height: 30, fontSize:12, fontWeight:400}} data-id={addr.id} onClick={useThisAddressHandler}>{titleForUsingButton}</Button><Row><IconButton aria-label="delete" data-id={addr.id} onClick={deleteAddress}><DeleteIcon /></IconButton></Row></Row>
        <Divider></Divider>
      </Column>
    );
  }

  

  /*const addNewAddressHandler = () => {
    //shipAddrForm = <ShippingAddressForm regions={props.regions}></ShippingAddressForm>;
    //showNewFormAddress(true);
    //console.log("add new address", shipAddrForm);
    navigate("/newaddress");
  }*/

  const addNewAddressHandler = (event) => {
    setCheckNewAddress(event.currentTarget.checked);
  }

  return (
    <div>
      <Accordion sx={{backgroundColor:Color.panelColor}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Subtitle1>All Saved Addresses</Subtitle1>
        </AccordionSummary>
        <AccordionDetails>
          <Column>
            <Divider></Divider>
            {content}
            <Column>
              <FormGroup>
                <FormControlLabel control={<Switch checked={checkNewAddress} onChange={addNewAddressHandler} inputProps={{ 'aria-label': 'controlled' }} />} label="Add New Address" />
              </FormGroup>
              { checkNewAddress &&
                <NewAddressForm></NewAddressForm>
              }
            </Column>
          </Column>
          
        </AccordionDetails>
      </Accordion>
    </div>
  );
}


function AddressForm(props) {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [streetLine1, setStreetLine1] = React.useState('');
  const [streetLine2, setStreetLine2] = React.useState('');
  const [streetLine3, setStreetLine3] = React.useState('');
  const [city, setCity] = React.useState('');
  const [zipCode, setZipCode] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [saveForFuture, setSaveForFuture] = React.useState(true);

  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const customerToken = stateObj.customerToken;
  const cart_id = stateObj.cart_id;
  const errMessage = stateObj.errMessage;

  let addressJson = shippingAddressJson;
  if (!props.forShipping) {
    addressJson = billingAddressJson;
  }

  addressJson.saveForFuture = saveForFuture;
  if (addressJson.hasOwnProperty('customer_address_id')) {
    delete addressJson.customer_address_id;
  }

  const firstNameHandler = (event) => {
    setFirstName(event.target.value);
    addressJson.firstName = event.target.value;
  }

  const lastNameHandler = (event) => {
    setLastName(event.target.value);
    addressJson.lastName = event.target.value;
  }

  const companyHandler = (event) => {
    setCompany(event.target.value);
    addressJson.company = event.target.value;
  }

  const streetLine1Handler = (event) => {
    setStreetLine1(event.target.value);
    if (shippingAddressJson.hasOwnProperty('streetLine') === false) {
      addressJson.streetLine = ["", "", ""];
    } 
    addressJson.streetLine[0] = event.target.value;
  }

  const streetLine2Handler = (event) => {
    setStreetLine2(event.target.value);
    if (addressJson.hasOwnProperty('streetLine') === false) {
      addressJson.streetLine = ["", "", ""];
    }
    addressJson.streetLine[1] = event.target.value;
    
  }

  const streetLine3Handler = (event) => {
    setStreetLine3(event.target.value);
    if (addressJson.hasOwnProperty('streetLine') === false) {
      addressJson.streetLine = ["", "", ""];
    }
    addressJson.streetLine[2] = event.target.value;
  }

  const cityHandler = (event) => {
    setCity(event.target.value);
    addressJson.city = event.target.value;
  }

  const zipCodeHandler = (event) => {
    setZipCode(event.target.value);
    addressJson.zipCode = event.target.value;
  }

  const phoneHandler = (event) => {
    setPhone(event.target.value);
    addressJson.phone = event.target.value;
  }

  const saveForFutureHandler = (event) => {
    setSaveForFuture(event.target.checked);
    addressJson.saveForFuture = event.target.checked;
    //console.log(addressJson);

    /*firstname: "${addressJson.firstName}"
    lastname: "${addressJson.lastName}"
    company: "${addressJson.company}"
    street: [${addressJson.street}]
    city: "${addressJson.city}"
    region: "${addressJson.regionCode}"
    postcode: "${addressJson.zipCode}"
    country_code: "${addressJson.countryCode}"
    telephone: "${addressJson.phone}"
    save_in_address_book: ${addressJson.saveForFuture}*/

    
  }

  return(
  <Column>
    <Row top={5} bottom={5}><TextField size="small" placeholder='First Name *' onChange={firstNameHandler}>{firstName}</TextField></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='Last Name *' onChange={lastNameHandler}>{lastName}</TextField></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='Company' onChange={companyHandler}>{company}</TextField></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='Street Line 1 *' onChange={streetLine1Handler}>{streetLine1}</TextField></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='Street Line 2' onChange={streetLine2Handler}>{streetLine2}</TextField></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='Street Line 3' onChange={streetLine3Handler}>{streetLine3}</TextField></Row>
    <Row top={8} bottom={8}><CountrySelect countries={props.countries} forShipping={props.forShipping}></CountrySelect></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='City *' onChange={cityHandler}>{city}</TextField></Row>
    <Row top={5} bottom={5}><TextField size="small" placeholder='Zip/Postal Code *' onChange={zipCodeHandler}>{zipCode}</TextField></Row>
    <Row top={5} bottom={10}><TextField size="small" placeholder='Phone Number *' onChange={phoneHandler}>{phone}</TextField></Row>
    <FormGroup>
      <FormControlLabel control={<Checkbox checked={saveForFuture} onChange={saveForFutureHandler}/>} label="Save for Future Purchases" />
    </FormGroup>
  </Column>
  );
}

function CountrySelect(props) {
  const [country, setCountry] = React.useState('');
  const [region, setRegion] = React.useState('');
  const [availRegions, setAvailRegions] = React.useState([]);
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const countries = stateObj.countries;
  let addrJson = shippingAddressJson;
  if (!props.forShipping) {
    addrJson = billingAddressJson;
  }
  //let countries = props.countries;

  const handleChangeCountries = (event) => {
    addrJson.countryCode = event.target.value;
    addrJson.regionCode = "";
    addrJson.regionId = null;

    let rg = [];
    const i = _.findIndex(countries, function(o) { return o.id === event.target.value; });
    if (i >= 0) {
      if (countries[i].available_regions !== null) {
        rg = countries[i].available_regions;
      }
    } else {
      rg = [];
      addrJson.regionId = null;
    }
    if (event.target.value === "") {
      delete addrJson.countryCode;
      delete addrJson.regionCode;
    } 
    setCountry(event.target.value);
    setRegion('');
    setAvailRegions(rg);
  };

  const handleChangeRegions = (event) => {
    addrJson.regionCode = event.target.value;
    const i = _.findIndex(availRegions, function(o) { return o.code === event.target.value; });
    console.log(i);
    if (i !== -1) {
      addrJson.regionId = availRegions[i].id;
    } else {
      addrJson.regionId = null;
    }
    if (event.target.value === "") {
      delete addrJson.regionCode;
    }
    setRegion(event.target.value);
  };

  try {
    function compare( a, b ) {
      if ( a.full_name_english < b.full_name_english ){
        return -1;
      }
      if ( a.full_name_english > b.full_name_english ){
        return 1;
      }
      return 0;
    }
    countries.sort(compare);
    let i = _.findIndex(countries, function(o) { return o.id == 'US'; });
    const el = {...countries[i]};
    countries.splice(i,1)
    countries.unshift(el);
  } catch {}

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label1">Country *</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select1"
          value={country}
          label="Country *"
          onChange={handleChangeCountries}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          { 
          countries.map((v,i)=>{
            return (<MenuItem key={"country"+i} value={v.id}>{v.full_name_english}</MenuItem>);
          })
          }
        </Select>
      </FormControl>
      

      { availRegions.length > 0 && <Column top={10}><FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Region *</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={region}
          label="Region *"
          onChange={handleChangeRegions}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          { 
          availRegions.map((v,i)=>{
            return (<MenuItem key={"region"+i} value={v.code}>{v.name}</MenuItem>);
          })
          }
        </Select>
      </FormControl></Column>}
    </Box>
  );
}

 

export default CheckoutView16;