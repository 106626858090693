import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { Divider, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle } from './Components';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 
import uuid from 'react-uuid';
import { getCustomerOrderInfo, resetState } from "../redux/actions";
var _ = require('lodash');

const margin = 40;

function SaleOrderView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const customerOrderInfo = stateObj.customerOrderInfo;
  const orderForPrint = stateObj.orderForPrint;
  const store = useStore();

  const navigate = useNavigate();

  let componentRef = React.useRef();

  const query = new URLSearchParams(window.location.search);
  //const number = query.get('number');
  const uuid = query.get('uuid');
  //const details = (decodeURI(query.get('details')));

  const isMobile = ('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/));

  console.log(orderForPrint);

  useEffect(()=>{
    /*dispatch(resetState({customerOrderInfo: null}));
    dispatch(getCustomerOrderInfo(customerToken, number));
    return(()=>{
      dispatch(resetState({customerOrderInfo: {}}));
    });*/
  }, []);

  const printHandler = ()=>{
    console.log("click");
  }

  const continueHandler = ()=>{
    navigate("/");
  }

  //console.log(customerOrderInfo);

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <>
        <Row left={margin} top={60}>
          <Row>
            <Button variant='contained' size="small" sx={{color:'white'}} onClick={continueHandler}>Continue shopping</Button>
          </Row>
        { isMobile === false && 
        <Row left={20} ><ReactToPrint
          trigger={() => <Button variant='contained' size="small" sx={{color:'white'}} onClick={printHandler}>Print</Button>}
          content={() => {
            return componentRef
          }}
        />
        </Row>
        }
        </Row>
        <Row top={60}></Row>
        <ComponentToPrint details={orderForPrint} ref={(el) => (componentRef = el)} />
      </>
    </ThemeProvider>
    
  );
}

class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
    //console.log(this.props.details);
    this.orderInfo = this.props.details;
    if (this.orderInfo.hasOwnProperty("orderData") === false) {
      this.product_options = JSON.parse(this.props.details.product_options);
      //console.log(this.product_options);
    } else {
      this.orderData = this.orderInfo.orderData.customer.orders.items[0];
    }
  }

  /*render() {
    return(
    <Row>Hello</Row>
    )
  }*/

  

  render() {
    console.log(this.orderData);
    if (this.product_options) {
      return (
        <>
          <Column key={uuid()} left={margin} >
            <Row bottom={30}><img width={100} height={50} src="/images/logo.png"></img></Row>
            <Row><Subtitle2>43 West 47th Street, Main Level</Subtitle2></Row>
            <Row><Subtitle2>New York, NY 10036</Subtitle2></Row>
            <Row><Subtitle2>P. 212 997 4343 F. 212 997 9701</Subtitle2></Row>
            <Row><Subtitle2>Toll Free 855 997 9701</Subtitle2></Row>
            <Row><Subtitle2>info@jrcastings.com</Subtitle2></Row>
            <Row><Subtitle2>www.jrcastings.com</Subtitle2></Row>
            <Row self={Axis.self.center} top={40} bottom={40}><Header6 sx={{ letterSpacing:1.2}}>SALE ORDER #{this.props.details.number}</Header6></Row>
            <Row><Row width={150}>Date:</Row><Row> {this.props.details.date}</Row></Row>
            <Row><Row width={150}>Customer Name:</Row> {this.product_options.options.map((v,i)=>{
              if (v.label === "Customer") {
                return <Row key={uuid()}>{v.value}</Row>;
              }
            })}</Row>
            <Row><Row width={150}>Account #:</Row> {this.product_options.options.map((v,i)=>{
              if (v.label === "Account") {
                return <Row key={uuid()}>{v.value}</Row>;
              }
            })}</Row>
            <Row><Row width={150}>Phone #:</Row> {this.product_options.options.map((v,i)=>{
              if (v.label === "Phone") {
                return <Row key={uuid()}>{v.value}</Row>;
              }
            })}</Row>
            <Row><Row width={150}>Sales Person:</Row> {this.product_options.options.map((v,i)=>{
              if (v.label === "Sales") {
                return <Row key={uuid()}>{v.value}</Row>;
              }
            })}</Row>
            <Row top={20}></Row><Divider sx={{width:"calc(100% - 40px)"}}></Divider><Row bottom={20}></Row>
            <Column>
              <Row><Row width={150}>Style:</Row> {this.product_options.options.map((v,i)=>{
                if (v.label.includes("-")) {
                  return <Row key={uuid()}>{v.value.split("*")[0]}</Row>;
                }
              })}</Row>
              <Row><Row width={150}>Material:</Row> {this.product_options.options.map((v,i)=>{
                if (v.label === "Material") {
                  return <Row key={uuid()}>{v.value}</Row>;
                }
              })}</Row>
              <Row><Row width={150}>Notes:</Row> {this.product_options.options.map((v,i)=>{
                if (v.label === "Notes") {
                  return <Row key={uuid()}>{v.value}</Row>;
                }
              })}</Row>
              <Row><Row width={150}>Pieces:</Row>{this.product_options.info_buyRequest.qty}</Row>
            </Column>
          </Column>
        </>
      );
    } else {
      return(
        <div>
        {_.isEmpty(this.orderData) === false && this.orderData.items.map((v,i)=>{
          return(
          <>
          <Column key={uuid()} left={margin} >
            <Row bottom={30}><img width={100} height={50} src="/images/logo.png"></img></Row>
            <Row><Subtitle2>43 West 47th Street, Main Level</Subtitle2></Row>
            <Row><Subtitle2>New York, NY 10036</Subtitle2></Row>
            <Row><Subtitle2>P. 212 997 4343 F. 212 997 9701</Subtitle2></Row>
            <Row><Subtitle2>Toll Free 855 997 9701</Subtitle2></Row>
            <Row><Subtitle2>info@jrcastings.com</Subtitle2></Row>
            <Row><Subtitle2>www.jrcastings.com</Subtitle2></Row>
            <Row self={Axis.self.center} top={40} bottom={40}><Header6 sx={{ letterSpacing:1.2}}>SALE ORDER #{this.orderData.items.length === 1 ? this.orderData.number : this.orderData.number+"/"+parseInt(i+1)}</Header6></Row>
            <Row><Row width={150}>Date:</Row><Row> {this.orderData.order_date}</Row></Row>
            <Row><Row width={150}>Customer Name:</Row> {v.selected_options.map((v,i)=>{
              if (v.label === "Customer") {
                return <Row key={uuid()}>{v.value}</Row>;
              }
            })}</Row>
            <Row><Row width={150}>Account #:</Row> {v.selected_options.map((v,i)=>{
              if (v.label === "Account") {
                return <Row key={uuid()}>{v.value}</Row>;
              }
            })}</Row>
            <Row><Row width={150}>Phone #:</Row> {v.selected_options.map((v,i)=>{
              if (v.label === "Phone") {
                return <Row key={uuid()}>{v.value}</Row>;
              }
            })}</Row>
            <Row><Row width={150}>Sales Person:</Row> {v.selected_options.map((v,i)=>{
              if (v.label === "Sales") {
                return <Row key={uuid()}>{v.value}</Row>;
              }
            })}</Row>
            <Row top={20}></Row><Divider sx={{width:"calc(100% - 40px)"}}></Divider><Row bottom={20}></Row>
            <Column>
              <Row><Row width={150}>Style:</Row> {v.entered_options.map((v,i)=>{
                if (v.label.includes("-")) {
                  return <Row key={uuid()}>{v.value.split("*")[0]}</Row>;
                }
              })}</Row>
              <Row><Row width={150}>Material:</Row> {v.entered_options.map((v,i)=>{
                if (v.label === "Material") {
                  return <Row key={uuid()}>{v.value}</Row>;
                }
              })}</Row>
              <Row><Row width={150}>Notes:</Row> {v.selected_options.map((v,i)=>{
                if (v.label === "Notes") {
                  return <Row key={uuid()}>{v.value}</Row>;
                }
              })}</Row>
              <Row><Row width={150}>Pieces:</Row>{v.quantity_ordered}</Row>
            </Column>
          </Column>
          <div class="pagebreak"> </div>
          <Row top={60}></Row>
          </>
          );
        })}
        </div>
      );
    }
  }

}



export default SaleOrderView16;