import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { Divider, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';

import { Card, CardContent, CardMedia,  } from '@mui/material';

//import uuid from 'react-uuid';

import { 
  resetState,
  resetPassword,
  logOutMagento,
  logInMagento,
} from "../redux/actions";

function ResetPasswordView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}


function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errType;
  const resetPasswordResult = stateObj.resetPasswordResult;

  const query = new URLSearchParams(window.location.search);
  let token = query.get('token').replace(",", "");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(()=>{
    //dispatch(resetState({resetPasswordResult:null}));
    if (resetPasswordResult) {
      navigate("/login");
    }
  },[resetPasswordResult]);

  let alert = null;
  if (errMessage) {
    let e = errMessage;
    alert = <Row width={lt.bunch4} bottom={10}>
      <Alert severity='error' sx={{marginTop:"20px", marginBottom:"10px"}} onClose={() => {
        dispatch(resetState({errType: "", errMessage: ""}));
      }}><AlertTitle>{errType}</AlertTitle>{e}
      </Alert></Row>;
  } else {
    
  }

  const emailHandler = (event)=>{
    setEmail(event.target.value);
  }

  const passwordHandler = (event)=>{
    setPassword(event.target.value);
  }

  const submitHandler = (event)=>{
    dispatch(resetPassword(email, token, password));
  }

  return(
    <Column width="100%" center>
      <Column width={lt.innerWidth} top={40} bottom={40} cross={Axis.cross.center}>
        { !resetPasswordResult &&
        <Column width={lt.bunch4}>
          <Row top={10} bottom={30}><Header5>Create New Password</Header5></Row>
          {alert}
          <TextField
            autoFocus
            margin="dense"
            id="loginemail"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={emailHandler}
          />
          <TextField
            margin="dense"
            id="loginpassword"
            label="New Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={passwordHandler}
          /> 
          <Row top={30}><Button variant='contained' sx={{color:"#fff"}} onClick={submitHandler}>Submit</Button></Row>
        </Column>
        }
      </Column>
    </Column>
  );
}

//<CircularProgress size={30}></CircularProgress>


export default ResetPasswordView16;