import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import LoginPage from './LoginPage';
import ProfilePage from './ProfilePage';
import ProductPage from './ProductPage';
import CartPage from './CartPage';
import CartResultPage from './CartResultPage';
import AboutPage from './AboutPage';
import ContactPage from './ContactPage';
import FaqPage from './FaqPage';
import CheckoutPage from './CheckoutPage';
import PayPage from './PayPage';
import SpecialService3dwaxPage from './SpecialService3dwaxPage';
import SpecialServiceDesignPage from './SpecialServiceDesignPage';
import ConfirmationPage from './ConfirmationPage';
import MobilMenuPage from './MobilMenuPage';
import CategoryPage from './CategoryPage';
import ProductsListPage from './ProductsListPage';
import OrderInfoPage from './OrderInfoPage';
import SearchPage from './SearchPage';
import SearchedOrdersPage from './SearchedOrdersPage';
import SaleOrderPage from './SaleOrderPage';
import ResetPasswordPage from './ResetPasswordPage';
import NewStylePage from './NewStylePage';
import {Viewer} from './viewer2';
import StoneCalcPage from './StoneCalcPage';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Routes>
          <Route exact path="/" element={
              <App />
          } />
          <Route exact path="/login" element={
              <LoginPage />
          } />
          <Route exact path="/profile" element={
              <ProfilePage />
          } />
          <Route exact path="/product" element={
              <ProductPage />
          } />
          <Route exact path="/cart" element={
              <CartPage />
          } />
          <Route exact path="/cart_result" element={
              <CartResultPage />
          } />
          <Route exact path="/about" element={
            <AboutPage />
          } />
          <Route exact path="/contacts" element={
            <ContactPage />
          } />
          <Route exact path="/faq" element={
            <FaqPage />
          } />
          <Route exact path="/checkout" element={
            <CheckoutPage />
          } />
          <Route exact path="/pay" element={
            <PayPage />
          } />
          <Route exact path="/spec3dwax" element={
            <SpecialService3dwaxPage />
          } />
          <Route exact path="/specdesign" element={
            <SpecialServiceDesignPage />
          } />
          <Route exact path="/confirmation" element={
            <ConfirmationPage />
          } />
          <Route exact path="/view3d" element={
            <Viewer />
          } />
          <Route exact path="/mobilmenu" element={
            <MobilMenuPage />
          } />
          <Route exact path="/category" element={
            <CategoryPage />
          } />
          <Route exact path="/productslist" element={
            <ProductsListPage />
          } />
          <Route exact path="/orderinfo" element={
            <OrderInfoPage />
          } />
          <Route exact path="/search" element={
            <SearchPage />
          } />
          <Route exact path="/search_orders" element={
            <SearchedOrdersPage />
          } />
          <Route exact path="/sale_order" element={
            <SaleOrderPage />
          } />
          <Route exact path="/create_password" element={
            <ResetPasswordPage />
          } />
          <Route exact path="/newstyles" element={
            <NewStylePage />
          } />
          <Route exact path="/stone_calc" element={
            <StoneCalcPage />
          } />
        </Routes>
      </Router>

    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
