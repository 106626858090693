import React, {useState, useEffect} from 'react';
import CheckoutView16 from './View16/Checkout16';


function CheckoutPage() {
  return (
    <CheckoutView16 />
  );
}

export default CheckoutPage;