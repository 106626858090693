
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Color } from '../flatColors';

export const HeaderButton = styled(Button)`
  color:#333;
  font-size:13px;
  letter-spacing:0.9px;
  text-transform:uppercase;
  padding-left:20px;
  padding-right:20px;
  margin-left:10px;
  margin-right:10px;
  &:hover {
    color:#6abdd2;
  }
`;

export const HeaderMenuButton = styled(Button)`
  color:#000;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  height:53px;
  letter-spacing: 1.9px;
  padding-left:30px;
  padding-right:30px;
`;

export const LogoContainer = styled('a')`
  width:100px;
  text-decoration:none;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
`;

export const HeaderPhone = styled('a')`
  text-decoration:none;
  font-weight:600;
  color:#000;
  font-size:13px;
  letter-spacing:1.8px;
  position:relative;
  &:before {
    position:absolute;
    content:"";
    width:9px;
    height:9px;
    left:-18px;
    top:5px;
    display:block;
    background: url("/images/icons/phone.svg");
  }
  &:focus {
    color:#6abdd2;
    outline:5px auto;
    outline-offset:-2px;
    outline-color:${Color.flatBlack}
  }
  &:hover {
    color:#6abdd2;
  }
`;

export const SearchIcon = styled('div')`
  display:block;
  width:23px;
  height:23px;
  background:url("/images/icons/search.svg");
  background-size:contain;
  background-repeat:no-repeat;
`;

export const SearchLabel = styled('div')`
  align-items:center;
  font-size:13px;
  letter-spacing:0.9px;
  text-transform:uppercase;
  margin-left:10px;
  &:hover {
    color:#6abdd2;
  }
`;

export const SearchWraper = styled('div')`
  cursor:pointer;
`;

export const LoginIcon = styled('div')`
  display:block;
  width:25px;
  height:23px;
  background:url("/images/icons/user.svg");
  background-size:contain;
  background-repeat:no-repeat;
`;

export const LoginWraper = styled('div')`
  cursor:pointer;
`;

export const LoginLabel = styled('div')`
  align-items:center;
  font-size:13px;
  letter-spacing:0.9px;
  text-transform:uppercase;
  margin-left:10px;
  &:hover {
    color:#6abdd2;
  }
`;



export const BagIcon = styled('div')`
  display:block;
  width:25px;
  height:23px;
  background:url("/images/icons/cart.svg");
  background-size:contain;
  background-repeat:no-repeat;
`;

export const BagWraper = styled('div')`
  cursor:pointer;
`;

export const BagLabel = styled('div')`
  align-items:center;
  font-size:13px;
  letter-spacing:0.9px;
  text-transform:uppercase;
  margin-left:10px;
  &:hover {
    color:#6abdd2;
  }
`;

export const CallBackButton = styled(Button)`
  width:116px;
  height:28px;
  color:#fff;
  background-color: #eec0c0;
  line-height: 28px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-left: 19px;
  border-radius: 15px;
  cursor: pointer;
  margin:0;
  padding:0;
  text-transform:none;
  &:hover {
    background-color:rgba(248,213,212,1);
  }
`;

export const HeaderNav = styled('div')`
  display:flex;
  flex-direction:row;
  align-items:center;
  width:${props => props.width};
  height: 53px;
  background-color: hsla(8, 44%, 64%, 0.1);
  justify-content:center;
`;

export const HeaderMenu = styled('div')`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.9px;
  text-transform: uppercase;
`;

export const HeaderMenuList = styled('ul')`
  display: flex;
  align-items: center;
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const FormTitle = styled('h1')`
  font-size:16px;
  font-weight:600;
  color:#000;
  padding: 0px 0px;
`;

export const FormSubTitle = styled('h2')`
  font-size:12px;
  font-weight:500;
  color:#333;
  padding: 0px 0px;
  margin: 0px 0px;
`;

export const FormText = styled('p')`
  font-size:12px;
  font-weight:400;
  color:#000;
  padding: 0px 0px;
  margin: 0px 0px;
`;

export const FormButton = styled(Button)`
  padding:0px 15px;
  height: 30px;
  line-height: 24px;
  color: #6abdd2;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border: 3px solid #6abdd2;
  border-radius: 15px;
  &:hover {
    background-color:#6abdd2;
    color:#fff;
  }
`;