import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { Divider, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle } from './Components';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";

function OrderInfoView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}
 


function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;

  console.log(customerToken);

  /*if (customerToken !== "") {
    if (errMessage === "") {
      navigate(`/`);
    }
  }*/


  return(
    <Column width="100%" center>
      <Column width={lt.innerWidth} top={40} cross={Axis.cross.center}>
      OrderInfoView16
      </Column>
    </Column>
  );
}

function MainMenu(props) {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;

  return(
    <Row>
        <HeaderNav width={window.innerWidth+"px"}>
          <Row width={lt.innerWidth}>
            {props.children}
          </Row>
        </HeaderNav>
    </Row>
  );
}


function ContactUsMenu() {

  const handleClick = (event) => {
    
  };

  return(
    <Typography>
      <HeaderMenuButton
        onClick={handleClick}
      >
        Contact Us
      </HeaderMenuButton>
    </Typography>
  );
}





export default OrderInfoView16;