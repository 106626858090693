import React, {useState, useEffect} from 'react';
import PayView16 from './View16/Pay16';


function PayPage() {
  return (
    <PayView16 />
  );
}

export default PayPage;