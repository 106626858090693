import logo from './logo.svg';
import './App.css';
import React from 'react';
import { useStore } from "react-redux";
import View16 from './View16/View16'; 

function App() {
  const store = useStore();

  React.useEffect(()=>{
    document.title = "JR Castings";
  },[]);

  return (
    <View16></View16>
  );
}

export default App;
