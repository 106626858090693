import '../App.css';
import React, {useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { logInMagento, logOutMagento, setCartTotal, getCategoryTree, searchProducts, resetState } from "../redux/actions";
import { Row, Column, Axis, Dbg, ContentContainer, Head, Content, Filler, Footer, Stack, StackItem, Header, MainNav, NodeRoot, Node, NodeItem } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
//import styled from 'styled-components';
import styled from "@emotion/styled/macro";
import { Divider, IconButton, Typography } from '@mui/material';
import Face from '@mui/icons-material/Face';
import { ArrowBackIos, Phone, Search, ShoppingBag } from '@mui/icons-material';
import { HeaderButton, LogoContainer, CallBackButton, HeaderNav, HeaderMenuButton, FormButton }  from './Components';
import Person from '@mui/icons-material/Person';
import { Link, useNavigate } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import MenuList from '@mui/material/MenuList';
import ArrowRight from '@mui/icons-material/ChevronRightRounded';
import { getCartTotalProducts, isTokenValid } from '../ApiHelper';
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline, A } from '../Components/TypographyComponents'; 
import uuid from 'react-uuid';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const _ = require('lodash');


function View16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const store = useStore();
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const cartTotal = stateObj.cartTotal;
  const [ menuOpened, setMenu ] = useState(false);

  useEffect(()=>{
    if (customerToken !== "") {
      isTokenValid(customerToken, (result)=>{
        if (result) {
          /*getCartTotalProducts(customerToken, (total)=>{
            if (total !== cartTotal) {
              dispatch(setCartTotal(total));
            }
          });*/
        } else {
          dispatch(logOutMagento());
        }
      });
    }
    //dispatch(resetState({searchedProducts:[]}));
  }, [
    customerToken,
    menuOpened,
  ]);
  
  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav></MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}



function Body(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const categoryTree = stateObj.categoryTree;
  const ref = useRef(null);
  let tree = {};
  let reactTree = <div></div>;

  //console.log(props.showMenu);

  useEffect(()=>{
    if (Object.keys(categoryTree).length === 0) { 
      dispatch(getCategoryTree());
    }
  }, [
    JSON.stringify(categoryTree),
    lt.containerWidth
  ]);

  //console.log(categoryTree);
  
  if (Object.keys(categoryTree).length > 0) {
    //console.log(categoryTree);
    tree = categoryTree[0].children[0];
    /*tree = parseJsonAsHTMLTree(categoryTree[0].children[0]);
    tree = '<ul>'+tree+'</ul>';
    const htmlToReactParser = new HtmlToReactParser();
    reactTree = htmlToReactParser.parse(tree);*/
  }

  const navigate = useNavigate();

  const selectProductHandler = ()=>{
    navigate("product?sku=ew-1131-1151");
  }

  let selectProduct = <div></div>
  if (customerToken !== "") {
    selectProduct = <FormButton onClick={selectProductHandler}>Select Product</FormButton>
  }

  let categories = [];
  if (tree) {
    if (Array.isArray(categoryTree) && categoryTree.length > 0 && categoryTree[0].hasOwnProperty('children')) {
      categories = categoryTree[0].children;
    }
  }

  //console.log(categories);

  /*let catProducts = {};
  if (categories.length > 0) {
    catProducts = categories[0];
  }*/

  //console.log(catProducts);

  let leftMenuWidth = lt.bunch4;
  let rightPanelWidth = lt.bunch12;
  if (lt.max === 12) {
    rightPanelWidth = lt.bunch8;
  } else if (lt.max === 8) {
    rightPanelWidth = lt.bunch4;
  }

  return(
    <Column width="100%" cross={Axis.cross.center}>
      <Column width={lt.innerWidth} >
        <div style={{display: (lt.max === 4 || lt.max === 8) ? "block": "none"}}>
          <Column top={lt.max === 8 ? 20 : 15}>
            <Column bottom={lt.max === 8 ? 10 : 20}>
              <MainBanner></MainBanner>
            </Column>
            <Banners></Banners>
          </Column>
        </div>
        <div style={{display: lt.max == 16 ? "block": "none"}}>
        
          <Row>
            <Column width={leftMenuWidth} >
              <Column top={20} left={20} right={0} height="100%" sx={{boxShadow: "6px 6px 13px 0 rgba(0,0,0,0.1)"}}>
                <NodeRoot>
                  <Row top={10}></Row>
                  <CategoryTree tree={tree}></CategoryTree>
                </NodeRoot>
              </Column>
            </Column>
            <Banners></Banners>
            {/*<Column>
              <div ref={ref}></div>
              {<MainLevelMenu node={catProducts} anchor={ref ? ref.current : null}></MainLevelMenu>}
            </Column>*/}
          </Row>
        </div>
        <div style={{display: lt.max === 12 ? "block": "none"}}>
          <Row top={20} bottom={5}>
            <Column width={leftMenuWidth} >
              <Column top={0} left={20} right={0} height="100%" sx={{boxShadow: "6px 6px 13px 0 rgba(0,0,0,0.1)"}}>
                <NodeRoot>
                  <Row top={10}></Row>
                  <CategoryTree tree={tree}></CategoryTree>
                </NodeRoot>
              </Column>
            </Column>
            <Column left={lt.gutter}><MainBanner></MainBanner></Column>
          </Row>
          <Banners></Banners>
        </div>
      </Column>
      <Column top={20} width='100%'>
        <Requirments></Requirments>
      </Column>
    </Column>
  );
}


function Banners() {  
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const navigate = useNavigate();

  let bannerPanelWidth = lt.bunch12;
  if (lt.max === 16) {
    bannerPanelWidth = lt.bunch12;
  } else if (lt.max === 12) {
    bannerPanelWidth = lt.bunch12;
  } else if (lt.max === 8) {
    bannerPanelWidth = lt.bunch8;
  } else if (lt.max === 4) {
    bannerPanelWidth = lt.bunch4;
  }

  return(
    <Column width={bannerPanelWidth} left={lt.max === 16 ? 10: 0} >
      <div style={{display: lt.max === 4 ? "block": "none"}}>
        <Column width="100%" cross={Axis.main.center}>
          <Row width={bannerPanelWidth-20} height={(bannerPanelWidth-20)*(216/414)} >
            <div style={{borderColor: "#eee", borderStyle:'solid', borderWidth:1}} >
              <Link to="/category"><img src="/images/banner1_sm.jpg" style={{width:"100%", height:"100%", objectFit:"contain"}}></img></Link>
            </div>
          </Row>
          <Row width={bannerPanelWidth-20} height={(bannerPanelWidth-20)*(216/414)} top={15} >
            <div style={{borderColor: "#eec0c0", borderStyle:'solid', borderWidth:1}}>
            <Link to="/spec3dwax"><img src="/images/banner2_sm.jpg" style={{width:"100%", height:"100%", objectFit:"contain"}}></img></Link>
            </div>
          </Row>
          <Row width={bannerPanelWidth-20} height={(bannerPanelWidth-20)*(216/414)} top={15} >
            <div style={{borderColor: Color.mainColor, borderStyle:'solid', borderWidth:1}}>
              <Link to="/specdesign"><img src="/images/banner3_sm.jpg" style={{width:"100%", height:"100%", objectFit:"contain"}}></img></Link>
            </div>
          </Row>
        </Column>
      </div>
      <div style={{display: lt.max === 16 ? "block": "none"}}>
      <Row left={20} right={20} top={20} main={Axis.main.between}>
        <Column sx={{width: lt.max === 16 ? (bannerPanelWidth*0.5728)-40 : (bannerPanelWidth-40), height: lt.max === 16 ? ((bannerPanelWidth*0.5428)-40)*(558/583) : ((bannerPanelWidth-40)*(558/583))}} >
          <div style={{borderColor: "#eee", borderStyle:'solid', borderWidth:1}}>
            <Link to="/category"><img src="/images/banner1.jpg" style={{width:"100%", height:"100%", objectFit:"cover"}}></img></Link>
          </div>
        </Column>
        <Column main={Axis.main.between} sx={{width: (bannerPanelWidth*0.4572)-20, height: "100%"}}>
          <Row width="100%" height={((bannerPanelWidth*0.4572-20)*(279/478))} cross={Axis.cross.between}>
            <Row left={20} bottom={10}>
              <div style={{borderColor: "#eec0c0", borderStyle:'solid', borderWidth:1}}>
                <Link to="/spec3dwax"><img src="/images/banner2.jpg" style={{width:"100%", height:"100%", objectFit:"contain"}}></img></Link>
              </div>
            </Row>
          </Row>
          <Row width="100%" height={((bannerPanelWidth*0.4572-20)*(279/478))} >
            <Row left={20} top={10}>
              <div style={{borderColor: Color.mainColor, borderStyle:'solid', borderWidth:1}}>
                <Link to="/specdesign"><img src="/images/banner3.jpg" style={{width:"100%", height:"100%", objectFit:"contain"}}></img></Link>
              </div>
            </Row>
          </Row>
        </Column>
      </Row>
      </div>
      <div style={{display: lt.max > 4 && lt.max < 16 ? "block": "none"}}>
      <Row width="100%" top={20} main={Axis.main.between} >
        <Row left={20} width={(lt.innerWidth-50)/2} height={(lt.innerWidth-50)/2*(279/478)} >
          <div style={{borderColor: "#eec0c0", borderStyle:'solid', borderWidth:1}}>
            <Link to="/spec3dwax"><img src="/images/banner2.jpg" style={{width:"100%", height:"100%", objectFit:"cover"}}></img></Link>
          </div>
        </Row>
        <Row right={20} width={(lt.innerWidth-50)/2} height={(lt.innerWidth-50)/2*(279/478)} main={Axis.main.end} >
          <div style={{borderColor: Color.mainColor, borderStyle:'solid', borderWidth:1}}>
            <Link to="/specdesign"><img src="/images/banner3.jpg" style={{width:"100%", height:"100%", objectFit:"cover"}}></img></Link>
          </div>
        </Row>
      </Row>
      </div>
    </Column>
  );
}

function MainBanner() {  
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;

  let margin = 20;
  let bannerWidth = 0;
  let bannerHeight = 0;
  let bannerPanelWidth = lt.bunch12;
  if (lt.max === 16) {
    bannerPanelWidth = lt.bunch12;
    bannerWidth = (bannerPanelWidth*0.5728)-40;
    bannerHeight = ((bannerPanelWidth*0.5428)-40)*(558/583);
  } else if (lt.max === 12) {
    bannerPanelWidth = lt.bunch8;
    bannerWidth = (bannerPanelWidth)-40;
    bannerHeight = ((bannerPanelWidth)-40)*(558/583);
  } else if (lt.max === 8) {
    bannerPanelWidth = lt.bunch8;
    bannerWidth = (bannerPanelWidth)-40;
    bannerHeight = ((bannerPanelWidth)-40)*(558/583);
    margin = 10;
  } else if (lt.max === 4) {
    bannerPanelWidth = lt.bunch4;
    bannerWidth = (bannerPanelWidth)-20;
    bannerHeight = ((bannerPanelWidth)-20)*(558/583);
    margin = 10;
  }

  
  
  return(
    <Column width={bannerPanelWidth} cross={Axis.cross.center} >
      <Column width={bannerWidth} height={bannerHeight} >
        <div style={{borderColor: "#eee", borderStyle:'solid', borderWidth:1}}>
          <Link to="/category"><img src="/images/banner1.jpg" style={{width:"100%", height:"100%", objectFit:"cover"}}></img></Link>
        </div>
      </Column>
    </Column>
  );
}


function Requirments() {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  let shift = 20;
  if (lt.max === 4) {
    shift = 10;
  }

  return (
    <Column width="100%" color="#faecec" cross={Axis.cross.center}>
      <Column width={lt.innerWidth}>
        <Row left={shift} right={shift} self={Axis.self.center} top={65} bottom={40}><Header4 sx={{textTransform:"uppercase", fontWeight:600, letterSpacing:"3px", textAlign:'center'}}>Requirments for customers</Header4></Row>
        <div style={{display: (lt.max === 16 || lt.max === 12) ? 'block': 'none'}}>
          <Row main={Axis.main.between} top={20} left={20} right={20}>
            <RequirmentsItem backgroundImage="terms1.jpg" title="Must be a retailer" icon="icons/shopping-bag.svg"></RequirmentsItem>
            <RequirmentsItem backgroundImage="terms2.jpg" title="Must have a Tax ID" icon="icons/tax.svg"></RequirmentsItem>
            <RequirmentsItem backgroundImage="terms3.jpg" title="Must give References" icon="icons/gdpr.svg"></RequirmentsItem>
            <RequirmentsItem backgroundImage="terms4.jpg" title="Must have a Store Location" icon="icons/placeholder.svg"></RequirmentsItem>
          </Row>
        </div>
        <div style={{display: lt.max === 8 ? 'block': 'none'}}>
          <Column top={20}>
            <Row main={Axis.main.between} left={20} right={20}>
              <RequirmentsItem backgroundImage="terms1.jpg" title="Must be a retailer" icon="icons/shopping-bag.svg"></RequirmentsItem>
              <RequirmentsItem backgroundImage="terms2.jpg" title="Must have a Tax ID" icon="icons/tax.svg"></RequirmentsItem>
            </Row>
            <Row top={20} main={Axis.main.between} left={20} right={20}>
              <RequirmentsItem backgroundImage="terms3.jpg" title="Must give References" icon="icons/gdpr.svg"></RequirmentsItem>
              <RequirmentsItem backgroundImage="terms4.jpg" title="Must have a Store Location" icon="icons/placeholder.svg"></RequirmentsItem>
            </Row>
          </Column>
        </div>
        <div style={{display: lt.max === 4 ? 'block': 'none'}}>
          <Column top={20} center>
            <Row><RequirmentsItem backgroundImage="terms1.jpg" title="Must be a retailer" icon="icons/shopping-bag.svg"></RequirmentsItem></Row>
            <Row top={20}><RequirmentsItem backgroundImage="terms2.jpg" title="Must have a Tax ID" icon="icons/tax.svg"></RequirmentsItem></Row>
            <Row top={20}><RequirmentsItem backgroundImage="terms3.jpg" title="Must give References" icon="icons/gdpr.svg"></RequirmentsItem></Row>
            <Row top={20}><RequirmentsItem backgroundImage="terms4.jpg" title="Must have a Store Location" icon="icons/placeholder.svg"></RequirmentsItem></Row>
          </Column>
        </div>
        <Row bottom={65}></Row>
      </Column>
    </Column>
  );
}

const ReqWrapper = styled(Column)`
  background: url(../images/${props => props.img}) no-repeat;
  background-size: cover;
`;

function RequirmentsItem(props) {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;

  let width = (lt.innerWidth-20*5)/4;
  if (lt.max === 16 && lt.max === 12) {
    width = (lt.innerWidth-20*5)/4;
  } else if (lt.max === 8) {
    width = (lt.innerWidth-20*3)/2;
  } else if (lt.max === 4) {
    width = (lt.innerWidth-20);
  }
  return (
    <ReqWrapper img={props.backgroundImage} width={width} sx={{height: lt.max === 4 ? 260 : 260}} color={Color.flatSkyBlue} main={Axis.main.center}>
      <Row self={Axis.self.start} left={35} height={58} bottom={30}><img src={"/images/"+props.icon} style={{width:"100%", height:"100%"}}></img></Row>
      <Row self={Axis.self.start} left={35} right={10}><Header6 sx={{color:'#fff'}}>{props.title}</Header6></Row>
    </ReqWrapper>
  );
}

const ProductMenuButton = styled(Button)`
  color:#333;
  font-size:15px;
  letter-spacing:0.3px;
  padding-top:1px;
  padding-bottom:1px;
  &:hover {
    color:#6abdd2;
  }
`;

function CategoryTree(props) {
  if (_.isEmpty(props.tree)) {
    return null;
  }
  //console.log(props.tree);
  let categoryItems = props.tree.children.map((v,i)=>{
    return <MainTreeManager data={v} key={uuid()}></MainTreeManager>
  });
  return(
    <Node>
      {categoryItems}
    </Node>
  );
}

function MainTreeManager(props) {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  let renderObj = null;
  renderObj = basicTreeMethod(renderObj, props.data);
  const navigate = useNavigate();
  //console.log(parseInt(props.data.children_count));

  const categoryHandler = (event)=>{
    const obj = JSON.parse(event.target.dataset.data);
    console.log(obj);
    console.log(obj.children_count);
    if (parseInt(obj.children_count) > 0) {
      navigate(`/category/?uid=${obj.uid}`);
    } else {
      navigate(`/productslist/?uid=${obj.uid}&page=1`);
    }
  }

  return(
    <>
      {parseInt(props.data.children_count) > 0 ?
      <NodeItem style={{minWidth:lt.bunch4-70, paddingLeft:10, paddingRight:20, paddingTop:2.5, paddingBottom:2.5, backgroundColor:'#fff'}} >
        <Row height={44} main={Axis.main.between} cross={Axis.cross.center}>
          <ProductMenuButton sx={{textAlign:'left', width:"100%", justifyContent:'start'}} data-data={JSON.stringify(props.data)} onClick={categoryHandler}>{props.data.name}</ProductMenuButton>
          <Row><ArrowForwardIosIcon sx={{fontSize:17, color:'#333'}} /></Row>
        </Row>
        <Node closed pinRight style={{top:'calc(100% - 44px)', paddingTop:10, paddingBottom:10, backgroundColor:'#fff'}} >{renderObj}</Node>
      </NodeItem>
      :
      <NodeItem style={{minWidth:lt.bunch4-70, paddingLeft:10, paddingRight:20, paddingTop:2.5, paddingBottom:2.5, backgroundColor:'#fff'}} >
        <Row height={44} cross={Axis.cross.center} >
          <ProductMenuButton sx={{textAlign:'left', width:"100%", justifyContent:'start'}} data-data={JSON.stringify(props.data)} onClick={categoryHandler}>{props.data.name}</ProductMenuButton>
        </Row>
        {renderObj}
      </NodeItem>
      }
    </>
  );
}

function basicTreeMethod(renderObj, data) {
  if (data) {
    let d = {...data};
    const i = _.findIndex(d.children, (o)=>{ return o.name.includes("VIEW ALL") });
    if (i !== -1) {
      const el = {...d.children[i]};
      //console.log(el);
      d.children.splice(i,1);
      d.children.unshift(el);
    }

    //console.log(i);
    if (d.children) {
      renderObj = d.children.map(v => {
        //!v && console.log(data);
        let comp = null;
        comp = <MainTreeManager data={v} key={v.uid} />;
        return comp;
      });
    }
  }
  return renderObj;
}






export default View16;