import React, {useState, useEffect} from 'react';
import ProductsListView16 from './View16/ProductsList16';


function ProductsListPage() {
  return (
    <ProductsListView16 />
  );
}

export default ProductsListPage;