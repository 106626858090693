import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { getCategoryTree } from "../redux/actions";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import styled from 'styled-components'
import { Divider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

const _ = require('lodash');

function MobilMenuView16() {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav></MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const categoryTree = stateObj.categoryTree;
  let tree = {};

  useEffect(()=>{
    if (categoryTree.length === 0) { 
      dispatch(getCategoryTree());
    }
  }, [
    JSON.stringify(categoryTree),
  ]);

  if (Object.keys(categoryTree).length > 0) {
    tree = categoryTree[0];
  }


  return(
    <Column width="100%" center>
      {_.isEmpty(tree) === false &&
        <CategoryFlatTree tree={tree}></CategoryFlatTree>
      } 
    </Column>
  );
}

const CatFlatTreeButton = styled(Button)`
  color:#333;
  width:100%;
  justify-content:${props=>props.align};
  align-items:center;
  text-align:left;
  text-transform:uppercase;
  &:hover {
    color:#6abdd6;
  }
`;

const CatFlatTreeButtonBack = styled(Button)`
  color:#333;
  font-weight:600;
  font-size:16px;
  width:100%;
  justify-content:${props=>props.align};
  align-items:center;
  text-align:left;
  text-transform:uppercase;
  &:hover {
    color:#6abdd6;
  }
`;

function CategoryFlatTree(props) {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const [ stack, setStack ] = useState([{name:"",uid:"", children_count:0, children:[]}]);
  const navigate = useNavigate();  

  //console.log(props.tree);

  const prodTree = props.tree.children[0];
  const newStyleTree = props.tree.children[1];

  const specserv = {
    name: "Special Services",
    uid: "specroot",
    children_count:2,
    children:[
      {
        name: "3D Wax print",
        uid: "spec3dwax",
        children_count:0,
        children:[],
      },
      {
        name: "Make your own design",
        uid: "specdesign",
        children_count:0,
        children:[],
      }
    ]
  };

  const productsHandler = (event)=>{
    let obj = JSON.parse(event.currentTarget.dataset.data);
    if (parseInt(obj.children_count) > 0) {
      let s = [...stack];
      const i = _.findIndex(obj.children, (o)=>{ return o.name.includes("VIEW ALL") });
      if (i !== -1) {
        const el = {...obj.children[i]};
        obj.children.splice(i,1);
        obj.children.unshift(el);
      }
      s.push(obj);
      setStack(s);
    } else {
      //console.log(obj.uid, obj.name);
      if (obj.uid === "spec3dwax") {
        navigate("/spec3dwax");
      } else if (obj.uid === "specdesign") {
        navigate("/specdesign");
      } else {
        //console.log(obj);
        if (obj.product_count > 0) {
          navigate(`/productslist/?uid=${obj.uid}&page=1`);
        } else {
          navigate(`/category/?uid=${obj.uid}`); //nav to product category
        }
      }
    }
  }

  const backHandler = ()=>{
    let s = [...stack];
    s.pop();
    setStack(s);
  }

  const newStyleHandler = ()=>{
    navigate("/");
  }

  const aboutUsHandler = ()=>{
    navigate("/about");
  }

  const contactUsHandler = ()=>{
    navigate("/contacts");
  }

  const faqHandler = ()=>{
    navigate("/faq");
  }

  return(
    <>
      <div style={{display: stack.length === 1 ? 'block': 'none'}}>
        <Column width={lt.innerWidth} top={20} bottom={100}>
          <Column cross={Axis.cross.start} width="100%">
            <Row width="100%" cross={Axis.cross.center} main={Axis.main.between} top={5} bottom={5}>
              <Row left={10} right={10} sx={{flex:1}}><CatFlatTreeButton align="space-between" endIcon={<ArrowForwardIosIcon/>} data-data={JSON.stringify(prodTree)} onClick={productsHandler}>Products</CatFlatTreeButton></Row>
            </Row>
            <Row width="100%" center><Divider sx={{width:"calc(100% - 20px)"}}></Divider></Row>
            <Row width="100%" cross={Axis.cross.center} main={Axis.main.between} top={5} bottom={5}>
              <Row left={10} right={10} sx={{flex:1}}><CatFlatTreeButton align="space-between" endIcon={<ArrowForwardIosIcon/>} data-data={JSON.stringify(specserv)} onClick={productsHandler}>Special services</CatFlatTreeButton></Row>
            </Row>
            <Row width="100%" center><Divider sx={{width:"calc(100% - 20px)"}}></Divider></Row>
            <Row width="100%" cross={Axis.cross.center} main={Axis.main.between} top={5} bottom={5}>
              <Row left={10} right={10} sx={{flex:1}}><CatFlatTreeButton align="space-between" endIcon={parseInt(newStyleTree.children_count) > 0 ? <ArrowForwardIosIcon/> : null} data-data={JSON.stringify(newStyleTree)} onClick={productsHandler}>New styls</CatFlatTreeButton></Row>
            </Row>
            <Row width="100%" center><Divider sx={{width:"calc(100% - 20px)"}}></Divider></Row>
            <Row width="100%" cross={Axis.cross.center} main={Axis.main.between} top={5} bottom={5}>
              <Row left={10} right={10} sx={{flex:1}}><CatFlatTreeButton align="space-between" onClick={aboutUsHandler}>About us</CatFlatTreeButton></Row>
            </Row>
            <Row width="100%" center><Divider sx={{width:"calc(100% - 20px)"}}></Divider></Row>
            <Row width="100%" cross={Axis.cross.center} main={Axis.main.between} top={5} bottom={5}>
              <Row left={10} right={10} sx={{flex:1}}><CatFlatTreeButton align="space-between" onClick={contactUsHandler}>Contact us</CatFlatTreeButton></Row>
            </Row>
            <Row width="100%" center><Divider sx={{width:"calc(100% - 20px)"}}></Divider></Row>
            <Row width="100%" cross={Axis.cross.center} main={Axis.main.between} top={5} bottom={5}>
              <Row left={10} right={10} sx={{flex:1}}><CatFlatTreeButton align="space-between" onClick={faqHandler}>Faq</CatFlatTreeButton></Row>
            </Row>
            <Row width="100%" center><Divider sx={{width:"calc(100% - 20px)"}}></Divider></Row>
          </Column>
        </Column>
      </div>
      <div style={{display: stack.length > 1 ? 'block': 'none'}}>
        <Column width={lt.innerWidth} top={20} bottom={100}>
          <Row width="100%" cross={Axis.cross.center} main={Axis.main.between} top={5} bottom={5}>
            <Row left={10} right={10} sx={{flex:1}}>
              <CatFlatTreeButtonBack align="flex-start" startIcon={<ArrowBackIos />} onClick={backHandler}>{_.last(stack).name}</CatFlatTreeButtonBack>
            </Row>
          </Row>
          <Row width="100%" center><Divider sx={{width:"calc(100% - 20px)"}}></Divider></Row>
          { _.last(stack).children.map((v,i)=>{
              return(
                <React.Fragment key={v.uid}>
                  <Row width="100%" cross={Axis.cross.center} main={Axis.main.between} top={5} bottom={5}>
                    <Row left={10} right={10} sx={{flex:1}}>
                      { parseInt(v.children_count) > 0 ?
                        <CatFlatTreeButton align="space-between" endIcon={<ArrowForwardIosIcon/>} data-data={JSON.stringify(v)} onClick={productsHandler}>{v.name}</CatFlatTreeButton>
                      :
                        <CatFlatTreeButton align="space-between" data-data={JSON.stringify(v)} onClick={productsHandler}>{v.name}</CatFlatTreeButton>
                      }
                    </Row>
                  </Row>
                  <Row width="100%" center><Divider sx={{width:"calc(100% - 20px)"}}></Divider></Row>
                </React.Fragment>
              );
            })
          }
        </Column>
      </div>
    </>
  );
}



export default MobilMenuView16;