import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { Divider, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle } from './Components';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 

function SpecialService3DesignView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}
 
const Banner = styled(Column)`
  background: url(/images/main-bg2.jpg) no-repeat 50%;
  background-size: cover;
  border: 1px solid hsla(0,0% ,59%, 0.32);
  position:relative;
  &:before {
    display: ${props=>props.lt.max <= 8 ? 'block':'none'};
    background-color: hsla(0, 0%, 100%, 0.62);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    left: -1px;
    top: -1px;
    position: absolute;
    content: "";
  }
`;

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;

  const coeff = 458.0/1298.0;

  const orderHandler = ()=>{
    navigate("/contacts");
  }

  let textWidth = lt.bunch6;
  if (lt.max === 8) {
    textWidth = lt.bunch5;
  } else if (lt.max === 4) {
    textWidth = lt.bunch4-40;
  }

  return(
    <Column width="100%" center>
      <Column width={lt.innerWidth} top={40} bottom={40} cross={Axis.cross.center} >
        { lt.max >= 8 ?
        <Column width={lt.innerWidth} >
          <Banner lt={lt} left={10} right={10} >
            <Column top={40} left={40} width={textWidth} >
              <Row><Header1 sx={{zIndex:100, fontWeight:600, fontSize:28, lineHeight:1.75, letterSpacing:3,textTransform:'uppercase'}}>MAKE YOUR OWN DESIGN WITH US STARTING FROM $59 ONLY!</Header1></Row>
              <Row top={20} sx={{zIndex:100}}>
                <Body1 sx={{fontSize:15,lineHeight:1.67,letterSpacing:1}}>
                We are making high quality jewelry design for every type of customer within 48 hours. We do any type of Bridal jewelry, Hip-hop jewelry, Fine jewelry.
                </Body1>
              </Row>
              <Row top={30} bottom={40} sx={{zIndex:100}}><Button variant='contained' sx={{color:'white', height:44, borderRadius:44/2, paddingLeft:'50px', paddingRight:'50px'}} onClick={orderHandler}>ORDER NOW</Button></Row>
            </Column>
          </Banner>
        </Column>
        :
        <Column width={lt.innerWidth} >
          <Banner lt={lt} left={10} right={10} >
            <Column top={20} width={textWidth} >
              <Column left={10} right={10} center>
                <Row><Header1 sx={{zIndex:100, fontWeight:600, fontSize:20, lineHeight:1.3, letterSpacing:3,textTransform:'uppercase'}}>3D WAX PRINT FOR $18 WITHIN 24 HOURS! GET YOUR CASTING THE NEXT DAY!</Header1></Row>
                <Row top={20} sx={{zIndex:100}}>
                  <Body1 sx={{fontSize:15,lineHeight:1.67,letterSpacing:1}}>
                    We will make a 3D wax print: rings, wedding bands, bracelets, earrings, Pendants etc with 2.4 hours based on a file of your model. Also we provide a great opportunity to make a cast with us in any metal for the next day.
                  </Body1>
                </Row>
                <Row top={30} bottom={40} sx={{zIndex:100}}><Button variant='contained' sx={{color:'white', height:44, borderRadius:44/2, paddingLeft:'50px', paddingRight:'50px'}} onClick={orderHandler}>ORDER NOW</Button></Row>
              </Column>  
            </Column>
          </Banner>
        </Column>
        }
      </Column>
    </Column>
  );
}


export default SpecialService3DesignView16;