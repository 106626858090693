import axios from 'axios';

const url = "https://jrcastings.site/graphql";
const urlRestV1 = "https://jrcastings.site/rest/default/V1";



export async function getCartTotalProducts(customerToken, callback) {
  try {
    //let headers = { 'Authorization': `Bearer ${customerToken}`};
    let headers = [
      "Content-Type:application/json",
      `Authorization: Bearer ${customerToken}`
    ];
    let query = `
    {
      customerCart {
        id
      }
    }
    `;
    /*(async () => {
      
    })().catch(err => {
      console.error(err);
    });*/

    //let result = await axios({url: url, method: 'post', headers: headers, data: { query: query }});
    let result = await axios({url:"https://jrcastings.com/webapi.php", method:"post", data: {command:"graphql", headers:headers, query:query}});
    if (result.data.hasOwnProperty("errors")) {
      console.log(String(result.data.errors[0].message));
      callback(-1);
    } else {
      let data = result.data.data;
      let cart_id = data.customerCart.id;
      query = `
      {
        cart(cart_id: "${cart_id}") {
          items {
            quantity
            product {
              name
            }
          }
        }
      }
      `;
      result = await axios({url:"https://jrcastings.com/webapi.php", method:"post", data: {command:"graphql", headers:headers, query:query}});
      //result = await axios({url: url, method: 'post', headers: headers, data: { query: query }});
      if (result.data.hasOwnProperty("errors")) {
        console.log(String(result.data.errors[0].message));
        callback(-1);
      } else {
        data = result.data.data;
        let total = 0;
        if (data.hasOwnProperty('cart')) {
          if (data.cart.hasOwnProperty('items')) {
            data.cart.items.map((v, i)=>{
              total += v.quantity;
            });
          }
        }
        callback(total);
      }
    }
  } catch(e) {
    console.log(e);
    callback(0);
  }
}

export async function isTokenValid(customerToken, callback) {
  try {
    //let headers = { 'Authorization': `Bearer ${customerToken}`};
    let headers = [
      "Content-Type:application/json",
      `Authorization: Bearer ${customerToken}`
    ];
    //console.log(headers);
    //let result = await axios({url: urlRestV1+"/customers/me",method: 'get', headers: headers});
    let result = await axios({url:"https://jrcastings.com/webapi.php", method:"post", data: {command:"is_token_valid", headers:headers}});
    if (result.data.hasOwnProperty("message")) {
      console.log(String(result.data.errors[0].message));
      callback(false);
    } else {
      callback(true);
    }
  } catch(e) {
    console.log(e);
    callback(false);
  }
}


