import React, {useState, useEffect} from 'react';
import SearchedOrdersView16 from './View16/SearchedOrders16';


function SearchedOrdersPage() {
  return (
    <SearchedOrdersView16 />
  );
}

export default SearchedOrdersPage;