import React, {useState, useEffect} from 'react';
import ConfirmationView16 from './View16/Confirmation16';


function ConfirmationPage() {
  return (
    <ConfirmationView16 />
  );
}

export default ConfirmationPage;