import '../App.css';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { Component, createRef, useEffect, useRef, useState } from 'react';
import { Stage, Layer, Image, Rect, Text, Circle, Line } from 'react-konva';
import useImage from 'use-image';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

var _ = require('lodash');

const ringSizes = {
  '2.5': 13.6,
  '3': 14.1,
  '3.25': 14.3,
  '3.5': 14.5,
  '3.75': 14.7,
  '4': 14.9,
  '4.25': 15.1,
  '4.5': 15.3,
  '4.75': 15.5,
  '5': 15.7,
  '5.25': 15.9,
  '5.5': 16.1,
  '5.75': 16.3,
  '6': 16.5,
  '6.25': 16.7,
  '6.5': 16.9,
  '6.75': 17.1,
  '7': 17.3,
  '7.25': 17.5,
  '7.5': 17.7,
  '7.75': 17.9,
  '8': 18.1,
  '8.25': 18.3,
  '8.5': 18.5,
  '8.75': 18.7,
  '9': 18.9,
  '9.25': 19.2,
  '9.5': 19.4,
  '9.75': 19.6,
  '10': 19.8,
  '10.25': 20.0,
  '10.5': 20.2,
  '10.75': 20.4,
  '11': 20.6,
  '11.25': 20.8,
  '11.5': 21.0,
  '11.75': 21.2,
  '12': 21.4,
  '12.25': 21.6,
  '12.5': 21.8,
  '12.75': 22.0,
  '13': 22.2,
  '13.25': 22.4,
  '13.5': 22.6,
  '13.75': 22.8,
  '14': 23.0
}

const gemSizes = {
  '0.5': 1.0,
  '0.67': 1.15,
  '0.75': 1.12,
  '1': 1.35,
  '1.5': 1.5,
  '2': 1.7,
  '2.5': 1.8,
  '3': 2.0,
  '3.5': 2.1,
  '4': 2.2,
  '5': 2.4,
  '6': 2.5,
  '7': 2.7,
  '8': 2.8,
  '9': 2.9,
  '10': 3.0,
  '11': 3.1,
  '12': 3.2,
  '14': 3.3,
  '15': 3.4,
  '16': 3.5,
  '17': 3.6,
  '18': 3.7,
  '20': 3.8,
  '22': 3.9,
  '23': 4.0,
  '25': 4.1,
  '30': 4.2,
  '33': 4.4,
  '35': 4.5,
  '38': 4.6,
  '40': 4.8,
  '47': 5.0,
  '50': 5.2,
  '60': 5.4,
  '63': 5.5,
  '65': 5.6,
  '75': 5.8,
  '80': 6.0,
  '95': 6.4,
  '100': 6.5,
  /*'110': 6.6,
  '117': 6.8,
  '125': 7.0,
  '133': 7.2,
  '150': 7.4,
  '155': 7.5,
  '160': 7.6,
  '175': 7.8,
  '190': 8.0,
  '200': 8.2,
  '215': 8.4,
  '225': 8.6,
  '250': 8.8,
  '275': 9.0,
  '285': 9.2,
  '300': 9.4,
  '315': 9.6,
  '335': 9.8,
  '350': 10.0,
  '375': 10.2,
  '400': 10.4,
  '425': 10.6,
  '450': 10.8,
  '475': 11.0,
  '500': 11.2,*/
}

let cdHUS = {
  "0.5":0.8,
  "1.5":0.863,
  "3":0.581,
  "5":0.692,
  "7":0.778,
  "10":0.865,
  "12":0.883,
  "15":1.061,
  "20":0.454,
  "25":0.689,
  "40":0.389,
  "50":0.653,
  "100":0.7,
}

let cdAirLine = {
  "0.5": 0.402,
  "3": 0.775,
  "5": 0.857, 
  "7": 0.658, 
  "10": 0.760,
  "12": 0.791,
  "15": 0.789,
  "25": 0.5, 
  "30": 0.446,
  "35": 0.498,
  "40": 0.524,
  "50": 0.581,
  "60": 0.660,    
  "100": 0.533,
}

let cdMcut = {
  "0.5": 0.907,
  "0.75": 1.016,
  "1.5": 0.999,
  "2": 0.886,
  "3": 0.775,
  "7": 0.331,
  "10": 0.368,
  "12": 0.395,
  "15": 0.332,
  "25": 0.296,
  "50": 0.370,
  "100": 0.488,
}

let cdCHA = {
  "0.5": 0.794,
  "1.5": 1.191,
  "5": 0.777,
  "10": 0.551,
  "15": 0.556,
  "20": 0.385,
  "100": 0.313,
}

let cdComfit = {
  "0.5": 0.880,
  "3": 0.880,
  "5": 1.048,
  "7": 1.156,
  "10": 0.448,
  "15": 0.476,
  "25": 0.539,
  "50": 0.171,
  "100": 0.244,
}

let cdFlatSiteUShape = {
  "0.5": 0.422,
  "1.5": 0.444,
  "3": 0.868,
  "5": 0.797,
  "7": 0.824,
  "10": 0.904,
  "15": 1.022,
  "50": 0.292,
  "100": 0.246,
}

let cdJBWideBand = {
  "0.5": 0.513,
  "1": 0.692,
  "5": 0.59,
  "7": 0.569,
  "15": 0.641,
  "20": 0.621,
  "25": 0.618,
  "30": 0.648,
  "50": 0.772,
  "100": 1.019,
}

let cdStraightProng = {
  "0.5": 0.880,
  "1.5": 0.880,
  "3": 0.880,
  "5": 1.048,
  "7": 1.156,
  "10": 0.448,
  "12": 0.448,
  "15": 0.476,
  "20": 0.448,
  "25": 0.486,
  "30": 0.375,
  "40": 0.442,
  "45": 0.449,
  "55": 0.612,
  "65": 0.563,
  "75": 0.373,
  "100": 0.244,
}

let cdUCut = {
  "0.5": 0.656,
  "1": 0.883,
  "1.5": 0.970,
  "2": 0.913,
  "3": 0.803,
  "5": 0.532,
  "7": 0.592,
  "10": 0.315,
  "15": 0.529,
  "20": 0.366,
  "25": 0.287,
  "100": 0.429,
}

let cdUCutComFit = {
  "0.5": 0.733,
  "1.5": 1.1,
  "2": 1.047,
  "3": 0.9,
  "7": 0.783,
  "10": 0.639,
  "15": 1.071,
  "25": 0.877,
  "50": 1.096,
  "100": 1.447,
}

let models = [
  {name:"Heavy U Shape (EW 1253-1289)", cd:cdHUS, description:"EW 1253-1289"},
  {name:"Air Line (EW 1131-1194)", cd:cdAirLine, description:"EW 1131-1194"},
  {name:"MCut (EW 2722-2745)", cd:cdMcut, description:"EW 2722-2745"},
  {name:"UCut (EW 2451-2471)", cd:cdUCut, description:"EW 2451-2471"},
  {name:"UCut Comfort Fit (EW 3417-3452)", cd:cdUCutComFit, description:"EW 3417-3452"},
  {name:"Channel Around (EW 1747-1780)", cd:cdCHA, description:"EW 1747-1780"},
  {name:"Comfort Fit (EW 1092-1110)", cd:cdComfit, description:"EW 1092-1110"},
  {name:"Flat Site U Shape (EW 2629-2645)", cd:cdFlatSiteUShape, description:"EW 2629-2645"},
  {name:"JB Wide Band (EW 3503-3534)", cd:cdJBWideBand, description:"EW 3503-3534"},
  {name:"Shared Prongs (EW 973-1065)", cd:cdStraightProng, description:"EW 973-1065"},
]

function getCuletDistance(model, gemSize) {
  let sz = parseFloat(gemSize)
  let data = _.find(models, m => m.name === model);
  let cd = data.cd;

  let prevKey = 0;
  let prevValue = cd[0.5];

  let items = [...Object.keys(cd)].sort((n1,n2) => n1 - n2);
  for (let i=0;i<items.length;i++) {
    let key = items[i];
    let item = cd[key];
    if (sz > parseFloat(prevKey) && sz <= key) {
      return calc(gemSize, prevKey, key, prevValue, item);
    }
    prevKey = key;
    prevValue = item;
  }
  return 0;

  function calc(gemSize, fromSize, toSize, prevDist, currentDist) {
    let sz = parseFloat(gemSize);
    let cc = (sz-fromSize)/(toSize-fromSize);
    let d = (currentDist-prevDist)*cc+prevDist;
    return d;
  }

}   

const canvasWidth = 500;
const canvasHeight = canvasWidth;


function StoneCalcView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav></MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}
 

function Body() {
  const [image] = useImage('/images/round_diamond.png');
  const [attributes, setAttributes] = useState(null);
  let refs = useRef([...Array(100).keys()].map(() => createRef())); //Array(100).fill(useRef(null));
  const [rs, setRS] = React.useState(6.5);
  const [gs, setGS] = React.useState(10);
  const [model, setModel] = React.useState(models[0].name);
  let refLayer = useRef(null);
  let refStage = useRef(null);

  useEffect(()=>{
    let attributes = getAttributes(model, gs, rs);
    console.log(attributes);
    setAttributes(attributes);
  }, [rs, gs, model])

  function rotateAroundPoint(shape, angleDegrees, point) {
    // sin + cos require radians
    let angleRadians = angleDegrees * Math.PI / 180; 
    
    const x =
      point.x +
      (shape.x() - point.x) * Math.cos(angleRadians) -
      (shape.y() - point.y) * Math.sin(angleRadians);
    const y =
      point.y +
      (shape.x() - point.x) * Math.sin(angleRadians) +
      (shape.y() - point.y) * Math.cos(angleRadians);

    // move the rotated shape in relation to the rotation point.
    shape.position({x: x, y: y});
   
    // rotate the shape in place around its natural rotation point 
    shape.rotation(shape.rotation() + angleDegrees); 
  }

  const imageLoaded = (el, index) => {
    let center = getPoint(0,0);
    rotateAroundPoint(el.imageNode, attributes.centralAngle*index, {x:center.x, y:center.y});
  }

  const handleChangeRingSize = (e) => {
    setAttributes(null);
    setRS(e.target.value);
  };

  const handleChangeGemSize = (e) => {
    setAttributes(null);
    setGS(e.target.value);
  };

  const handleChangeModel = (e) => {
    setAttributes(null);
    setModel(e.target.value);
  };

  
  return(
    <Column width="100%" center>
      <Column top={10} cross={Axis.cross.center}>
        { /*attributes &&
          <Column>
            {attributes.result === "Success" ?
              <Column>
                <Row><h3>Stones: {attributes.qty}, gap: {Math.round(attributes.gapMM*100)/100} mm</h3></Row>
                <Row center><p>Style: {_.find(models, m => m.name === model).description}</p></Row>
              </Column>
            :
              <Column>
                <Row><h3>Stones: {attributes.qty}, gap: {Math.round(attributes.gapMM*100)/100} mm</h3></Row>
                <Row center><p>Style: {_.find(models, m => m.name === model).description}</p></Row>
              </Column>
            }
          </Column>*/
        }
        <Row width="100%" top={30} main={Axis.main.center}>
          <Row width={350}>
            <FormControl fullWidth>
              <InputLabel id="select-label-model">Model</InputLabel>
              <Select
                labelId="select-model"
                id="select-model"
                value={model}
                label="Model"
                onChange={handleChangeModel}
              >
                { models.map((m)=>{
                  return(
                    <MenuItem key={m.name} value={m.name}>{m.name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Row>
          <Row width={150} left={16}>
            <FormControl fullWidth>
              <InputLabel id="select-label-ring-size">Ring Size</InputLabel>
              <Select
                labelId="select-ring-size"
                id="select-ring-size"
                value={rs}
                label="Ring Size"
                onChange={handleChangeRingSize}
              >
                { [...Object.keys(ringSizes)].sort((n1,n2) => n1 - n2).map((rs)=>{
                  return(
                    <MenuItem key={rs} value={rs}>{rs}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Row>
          <Row width={150} left={16}>
            <FormControl fullWidth>
              <InputLabel id="select-label-gem-size">Gem Size</InputLabel>
              <Select
                labelId="select-gem-size"
                id="select-gem-size"
                value={gs}
                label="Gem Size"
                onChange={handleChangeGemSize}
              >
                { [...Object.keys(gemSizes)].sort((n1,n2) => n1 - n2).map((gs)=>{
                  return(
                    <MenuItem key={gs} value={gs}>{gs}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Row>
        </Row>
      </Column>
      
      { attributes &&
      <Column>
        <Column center>
          <Stage width={canvasWidth} height={canvasHeight} ref={refStage}>
            <Layer ref={refLayer}>
              { [...Array(attributes.qty).keys()].map((d, index)=>{
                return(
                  <URLImage key={"diamond"+index} index={index} onLoad={imageLoaded} src="/images/round_diamond.png" x={getPoint(0,0).x-attributes.stoneWidth/2} y={getPoint(0,0).y-attributes.stoneHeight-attributes.innerRadius-attributes.culetDistance} width={attributes.stoneWidth} height={attributes.stoneHeight}/>
                )
              })}
              <Circle x={getPoint(0,0).x} y={getPoint(0,0).y} radius={attributes.innerRadius} stroke="#526D82" strokeWidth={1} />
              <Circle x={getPoint(0,0).x} y={getPoint(0,0).y} radius={attributes.innerRadius+attributes.culetDistance+attributes.stoneGirdle} stroke="#FBA834" strokeWidth={0}/>
              <URLImage src={`/images/n${attributes.qty}.png`} x={getPoint(0,0).x-200/2} y={getPoint(0,0).y-200/2} width={200} height={200}/>
            </Layer>
          </Stage>
        </Column> 
      </Column>
      }
    </Column>
  );
}

function getValue(value) {
  return value/35*canvasWidth; //30mm
}

function getPoint(x,y) {
  let centerX = canvasWidth/2;
  let centerY = canvasHeight/2;
  return {x:centerX+x, y:centerY-x};
}

function getAttributes(model, gs, rs) {
  let culetDistance = getCuletDistance(model, gs);
  console.log(culetDistance);
  let ringSize = ringSizes[rs];
  let innerRadius = ringSize/2;
  console.log(ringSize, innerRadius);
  let stoneHeight = gemSizes[gs]*0.623;
  let stoneGirdle = gemSizes[gs]*0.50;
  let stoneWidth = gemSizes[gs];
  let innerRadius2 = innerRadius+culetDistance+stoneHeight;
  let innerRadiusToGirdle = innerRadius+culetDistance+stoneGirdle;
  console.log(stoneHeight, innerRadius2);
  let circeLength = Math.PI*(innerRadiusToGirdle*2);
  console.log(circeLength, stoneWidth);
  let x = 0;
  let qty = Math.floor(circeLength/stoneWidth); 
  console.log("Start qty:", qty, "Start dist:", (circeLength-(qty*stoneWidth))/(qty-1));
  let k = 0;
  let result = "Success";
  const minDist = 0.1;
  const maxDist = 0.4;
  while(true) {
    let diff = circeLength-(qty*stoneWidth);
    let dist = diff/(qty-1);
    if (dist >= minDist && dist < maxDist) {
      break;
    } 
    if (dist >= maxDist) {
      let newQty = qty;
      let i = 0;
      while (true) {
        let d = (circeLength-(newQty*stoneWidth))/(newQty-1);
        if (d >= minDist && dist < maxDist) {
          qty = newQty;
          break;
        }
        newQty++;
        console.log("increase from", qty, "to", newQty);
        i++;
        if (i > 5) {
          break;
        }
      }
    } 
    if (dist < minDist) {
      let newQty = qty;
      let i = 0;
      while (true) {
        let d = (circeLength-(newQty*stoneWidth))/(newQty-1);
        if (d >= minDist && dist < maxDist) {
          qty = newQty;
          break;
        }
        newQty--;
        console.log("reduce from", qty, "to", newQty);
        i++;
        if (i > 5 ) {
          break;
        }
      }
    }
    k++;
    if (k >= 3) {
      console.log("Fail" );
      result = "Fail";
      break;
    }
  }
  console.log("Qty:", qty, "Dist:", (circeLength-(qty*stoneWidth))/(qty-1));
  let gap = (circeLength-(qty*stoneWidth))/(qty-1);
  let centralAngle = 360/qty;
  return {result:result, qty:qty, gapMM:gap, cdMM:culetDistance, gap:getValue(gap), culetDistance:getValue(culetDistance), stoneWidth:getValue(stoneWidth), stoneHeight:getValue(stoneHeight), stoneGirdle:getValue(stoneGirdle), innerRadius:getValue(innerRadius), innerRadius2:getValue(innerRadius2), centralAngle:centralAngle};
}

class URLImage extends React.Component {
  state = {
    image: null,
  };
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }
  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad);
  }
  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.addEventListener('load', this.handleLoad);
  }
  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image,
    });
    if (this.props.onLoad) {
      this.props.onLoad(this, this.props.index);
    }
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };
  render() {
    return (
      <Image
        x={this.props.x}
        y={this.props.y}
        width={this.props.width}
        height={this.props.height}
        onLoad={this.props.onLoad}
        image={this.state.image}
        ref={(node) => {
          this.imageNode = node;    
        }}
      />
    );
  }
}


export default StoneCalcView16;