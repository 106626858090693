import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { CircularProgress, Divider, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle } from './Components';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from "react-router-dom";
import { 
  placeOrderOfSalesManager,
  resetState,
  getCustomerOrderInfo,
} from "../redux/actions";

import uuid from 'react-uuid';

import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 
import { PLACE_ORDER_OF_SALES_MANAGER } from "../redux/types";

var _ = require('lodash');

function CartResultView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}


function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errType;
  const placedOrder = stateObj.placedOrder;
  const cart = stateObj.cart;

  //console.log(customerToken);

  const query = new URLSearchParams(window.location.search);
  const cart_id = query.get('cart_id');

  //console.log(cart_id);
  

  useEffect(()=>{
    dispatch(resetState({placedOrder:null, errType:"", errMessage:""}));
    dispatch(placeOrderOfSalesManager(customerToken, cart_id));
    
    //dispatch(resetState({cart:{}}));

    return(()=>{
      dispatch(resetState({errType:"", errMessage:""}));
    })
  }, []);

  console.log(placedOrder);
  /**/

  const continueShoppingHandler = () => {
    navigate("/");
  }

  const printHandler = (event) => {
    let obj = JSON.parse(event.currentTarget.dataset.data);
    dispatch(resetState({orderForPrint:obj}));
    navigate("/sale_order/?uuid="+uuid());
  }

  let success = null;  
  if (_.isEmpty(placedOrder) === false) {
    //console.log(placedOrder);
    success =
      <Column top={60} bottom={60}>
        <Header5>Thank you for your purchase!</Header5>
        <Row top={30}>
          <Body1>Your order number is: {placedOrder.order.order_number}.<br/>We'll email you an order confirmation with details and tracking info.</Body1>
        </Row>
        <Row top={40}>
          <Button onClick={printHandler} data-data={JSON.stringify(placedOrder)} variant='contained' size="small" sx={{color:'white'}}>Print</Button>
        </Row>
        <Row top={20}>
          <Button onClick={continueShoppingHandler} >Continue Shopping</Button>
        </Row>
      </Column>;
  } else {
    if (errMessage !== "") {
      success = 
      <Column top={60} bottom={60}>
        <Header5>Error</Header5>
        <Row top={30}>
          <Body1>Sorry, something went wrong. Your shopping bag is empty.</Body1>
        </Row>
        <Row top={40}>
          <Button onClick={continueShoppingHandler} variant='contained' sx={{color:'white'}}>Continue Shopping</Button>
        </Row>
      </Column>;
    }
  }

  /*return(
    <Column width="100%" center>
      <Column width={lt.innerWidth} top={40} cross={Axis.cross.center}>
        {success ? 
        success
        :
        <Row left={20} right={20} self={Axis.self.start} center='true'>
          <Header3>Please wait...</Header3><Row left={20}><CircularProgress size={30}></CircularProgress></Row>
        </Row>
        }

      </Column>
    </Column>
  );*/

  
  return(
    <Column width="100%" center>
      <Column width={lt.innerWidth} top={40} cross={Axis.cross.center}>
        <div style={{display: placedOrder === null ? 'block': 'none'}}>
          <Row width="100%" cross={Axis.cross.center}><Header3>Please wait</Header3><Row left={20}><CircularProgress size={30}></CircularProgress></Row></Row>
        </div>
        {success}
      </Column>
    </Column>
  );
}



export default CartResultView16;