import React, {useState, useEffect} from 'react';
import NewStyleView16 from './View16/NewStyle16';


function NewStylePage() {
  return (
    <NewStyleView16 />
  );
}

export default NewStylePage;