import React, {useState, useEffect} from 'react';
import LoginView16 from './View16/Login16';


function LoginPage() {
  return (
    <LoginView16 />
  );
}

export default LoginPage;