export const SET_CALCULATED_LAYOUT = "SET_CALCULATED_LAYOUT";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const SET_VALUE = "SET_VALUE";
export const GET_PRODUCT_INFO = "GET_PRODUCT_INFO";
export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export const SET_ERROR = "SET_ERROR";
export const SET_CART_TOTAL = "SET_CART_TOTAL";
export const GET_CART = "GET_CART";
export const CLEAR_ALL_ERRORS = "CLEAR_ALL_ERRORS";
export const SET_SHIPPING_ADDRESS = "SET_SHIPPING_ADDRESS";
export const SET_BILLING_ADDRESS = "SET_BILLING_ADDRESS";
export const SET_ADDRESSES_ON_CART = "SET_ADDRESSES_ON_CART";
export const RESET_ADDRESSES_ON_CART = "RESET_ADDRESSES_ON_CART";
export const CREATE_CUSTOMER_ADDRESS = "CREATE_CUSTOMER_ADDRESS";
export const DELETE_CUSTOMER_ADDRESS = "DELETE_CUSTOMER_ADDRESS";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const SET_CUSTOMER_ADDRESS_AS_DEFAULT = "SET_CUSTOMER_ADDRESS_AS_DEFAULT";
//export const SET_SHIPPING_METHOD = "SET_SHIPPING_METHOD";
//export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const GET_BRAINTREE_TOKEN = "GET_BRAINTREE_TOKEN";
export const PLACE_ORDER = "PLACE_ORDER";
export const GET_CATEGORY_TREE = "GET_CATEGORY_TREE";
export const GET_CATEGORY_INFO = "GET_CATEGORY_INFO";
export const GET_CUSTOMER_ORDERS = "GET_CUSTOMER_ORDERS";
export const GET_CUSTOMER_ORDER_INFO = "GET_CUSTOMER_ORDER_INFO";
export const GET_PRODUCTS_LIST = "GET_PRODUCTS_LIST";
export const PLACE_ORDER_OF_SALES_MANAGER = "PLACE_ORDER_OF_SALES_MANAGER";

export const RESET_STATE = "RESET_STATE";
export const ACTIVATE_NEW_CUSTOMER = "ACTIVATE_NEW_CUSTOMER";
export const GET_FILES_FROM_STORAGE = "GET_FILES_FROM_STORAGE";

export const REQUEST_PASSWORD_RESET_EMAIL = "REQUEST_PASSWORD_RESET_EMAIL";
export const RESET_PASSWORD = "RESET_PASSWORD";


export const GET_COUNTRIES = "GET_COUNTRIES";

//export const GET_REGIONS = "GET_REGIONS";
export const GET_ADDRESSES = "GET_ADDRESSES";
export const SELECT_SHIPPING_ADDRESS_ID = "SELECT_SHIPPING_ADDRESS_ID";
export const SELECT_BILLING_ADDRESS_ID = "SELECT_BILLING_ADDRESS_ID";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
//export const SET_SALES_MANAGER_COUPON_TO_CART = "SET_SALES_MANAGER_COUPON_TO_CART";
export const GET_CUSTOMER_INFO = "GET_CUSTOMER_INFO";

export const ERR_TYPE_CUSTOMER_LOGIN = "CUSTOMER LOGIN";
export const ERR_TYPE_CREATE_CART = "ERR_TYPE_CREATE_CART";
export const ERR_TYPE_ADD_PRODUCT_TO_CART = "ERR_TYPE_ADD_PRODUCT_TO_CART";
export const ERR_TYPE_GET_PRODUCT_INFO = "ERR_TYPE_GET_PRODUCT_INFO";
export const ERR_TYPE_GET_CART = "ERR_TYPE_GET_CART";
export const ERR_TYPE_SET_SHIPPING_ADDRESS = "ERR_TYPE_SET_SHIPPING_ADDRESS";
export const ERR_TYPE_SET_BILLING_ADDRESS = "ERR_TYPE_SET_BILLING_ADDRESS";
export const ERR_TYPE_GET_COUNTRIES = "ERR_TYPE_GET_COUNTRIES";
//export const ERR_TYPE_GET_REGIONS = "ERR_TYPE_GET_REGIONS";
//export const ERR_TYPE_SET_PAYMENT_METHOD = "ERR_TYPE_SET_PAYMENT_METHOD";
export const ERR_TYPE_GET_BRAINTREE_TOKEN = "ERR_TYPE_GET_BRAINTREE_TOKEN";
export const ERR_TYPE_PLACE_ORDER = "ERR_TYPE_PLACE_ORDER";
//export const ERR_TYPE_SET_NONCE_TOKEN = "ERR_TYPE_SET_NONCE_TOKEN";
export const ERR_TYPE_GET_CATEGORY_TREE = "ERR_TYPE_GET_CATEGORY_TREE";
export const ERR_TYPE_GET_CATEGORY_INFO = "ERR_TYPE_GET_CATEGORY_INFO";
//export const ERR_TYPE_SET_SALES_MANAGER_COUPON_TO_CART = "ERR_TYPE_SET_SALES_MANAGER_COUPON_TO_CART";
export const ERR_TYPE_PLACE_ORDER_OF_SALES_MANAGER = "ERR_TYPE_PLACE_ORDER_OF_SALES_MANAGER";
export const ERR_TYPE_GET_ADDRESSES = "ERR_TYPE_GET_ADDRESSES";
export const ERR_TYPE_CREATE_CUSTOMER_ADDRESS = "ERR_TYPE_CREATE_CUSTOMER_ADDRESS";
export const ERR_TYPE_DELETE_CUSTOMER_ADDRESS = "ERR_TYPE_DELETE_CUSTOMER_ADDRESS";
export const ERR_TYPE_SET_CUSTOMER_ADDRESS_AS_DEFAULT = "ERR_TYPE_SET_CUSTOMER_ADDRESS_AS_DEFAULT";
export const ERR_TYPE_SELECT_SHIPPING_ADDRESS_ID = "ERR_TYPE_SELECT_SHIPPING_ADDRESS_ID";
export const ERR_TYPE_SELECT_BILLING_ADDRESS_ID = "ERR_TYPE_SELECT_BILLING_ADDRESS_ID";
export const ERR_TYPE_REMOVE_ITEM_FROM_CART = "ERR_TYPE_REMOVE_ITEM_FROM_CART";
export const ERR_TYPE_CREATE_CUSTOMER = "CREATE CUSTOMER";
//export const ERR_TYPE_GET_CUSTOMER_ORDERS = "ERR_TYPE_GET_CUSTOMER_ORDERS";
export const ERR_TYPE_SEARCH_PRODUCTS = "ERR_TYPE_SEARCH_PRODUCTS";
export const ERR_TYPE_ACTIVATE_NEW_CUSTOMER = "ERR_TYPE_ACTIVATE_NEW_CUSTOMER";
export const ERR_TYPE_GET_FILES_FROM_STORAGE = "ERR_TYPE_GET_FILES_FROM_STORAGE";
export const ERR_TYPE_GET_PRODUCTS_LIST = "ERR_TYPE_GET_PRODUCTS_LIST";
export const ERR_TYPE_GET_CUSTOMER_ORDER_INFO = "ERR_TYPE_GET_CUSTOMER_ORDER_INFO";
export const ERR_TYPE_GET_CUSTOMER_ORDERS = "ERR_TYPE_GET_CUSTOMER_ORDERS";
export const ERR_TYPE_GET_CUSTOMER_INFO = "GET CUSTOMER INFO";

export const ERR_TYPE_REQUEST_PASSWORD_RESET_EMAIL = "REQUEST PASSWORD RESET EMAIL";
export const ERR_TYPE_RESET_PASSWORD = "RESET PASSWORD";
