import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { Divider, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from "react-router-dom";

import { Card, CardContent, CardMedia,  } from '@mui/material';

//import uuid from 'react-uuid';

import { 
  searchProducts,
  resetState,
} from "../redux/actions";

function SearchView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}


function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const searchedProducts = stateObj.searchedProducts;

  const query = new URLSearchParams(window.location.search);
  const text = query.get('text');
  const uuid = query.get('uuid');
  const [pageInfo, setPageInfo] = useState({current_page:1, page_size:20, total_pages:0});

  //console.log(searchedProducts);

  useEffect(()=>{
    if (text) {
      if (text.length > 2) {
        dispatch(searchProducts(text, pageInfo.current_page, 20, (data)=>{
          console.log(data)
          if (data.hasOwnProperty("page_info")) {
            setPageInfo(data.page_info);
          }
        }));
      }
    }

  },[uuid]);

  let widthCard = (lt.bunch16-20)/5;
  if (lt.max === 12) {
    widthCard = (lt.bunch12-20)/4;
  } else if (lt.max === 8) {
    widthCard = (lt.bunch8-20)/3;
  } else if (lt.max === 4) {
    widthCard = (lt.bunch4-10)/2;
  }

  const itemHandler = (event)=>{
    let sku = event.currentTarget.dataset.sku;
    console.log(sku);
    navigate(`/product/?sku=${sku}`);
  }

  const handleChangePage = (event, value) => {    
    dispatch(searchProducts(text, value, 20, (data)=>{
      if (data.hasOwnProperty("page_info")) {
        setPageInfo(data.page_info);
      }
    }));
  };

  return(
    <Column width="100%" center>
      <Column width={lt.innerWidth} top={40} cross={Axis.cross.center}>
        <Container left={0} >
          { searchedProducts.map((v,i)=>{
            return(
              <Column key={v.uid} width={widthCard} top={10} bottom={10} data-sku={v.sku} onClick={itemHandler}>
                <CardWrapper sx={{marginLeft:"20px", marginRight:"0px"}} >
                  <CardMedia
                    component="img"
                    height={widthCard*0.7}
                    image={v.image.url}
                    sx={{objectFit:"contain"}}
                  />
                  <CardContent>
                    <Row bottom={10}>
                      <Column>
                        <Title>{v.name}</Title>
                        {/* v.short_description.hasOwnProperty("html") && v.short_description.html !== "" &&
                          <Subtitle1 sx={{fontSize:12, paddingTop:"10px"}}>{v.short_description.html}</Subtitle1>
                        */}
                      </Column>
                    </Row>
                    <DecoLine></DecoLine>
                  </CardContent>
                </CardWrapper>
              </Column>
            );
          })}
        </Container>
        { pageInfo.total_pages > 1 &&
          <Row top={40} bottom={40}><Pagination count={pageInfo.total_pages} page={pageInfo.current_page} onChange={handleChangePage} /></Row>
        }
      </Column>
    </Column>
  );
}

const Title = styled(Header1)`
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .8px;
`;

const Container = styled.div`
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:flex-start;
  margin-left:${props=>props.left}px;
  align-self:flex-start;
  ${props => props.debug && `
    border-style:dashed;
    border-width:1px;
    border-color:black;`
  }
`;

const DecoLine = styled.div`
  transition: width 0.2s linear;
  width: 0px;
  height: 2px;
  background-color: #eec0c0;
`;

const CardWrapper = styled(Card)`
  cursor:pointer;
  &:hover {
    color:${Color.mainColor};
    ${DecoLine} {
      width:30px;
      transition: width 0.2s linear;
    }
  }
`;



export default SearchView16;