import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { calc, Row, Column, Axis, Dbg, ContentContainer, Head, Content, Filler, Footer, Header, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { Divider, Typography } from '@mui/material';
import { DataObjectRounded, Phone, PinDropSharp, VpnLock } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle, FormText } from './Components';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 

import { 
  updateCalculatedLayout,
  logOutMagento, 
  setShippingAddress, 
  setBillingAddress,  
  getBraintreeToken, 
  placeOrder,
  setError,
  getCountries,
  getRegions,
  getAddresses,
  resetState,
} from "../redux/actions";
import { isTokenValid } from '../ApiHelper';
import { 
  ERR_TYPE_SET_SHIPPING_ADDRESS, 
  ERR_TYPE_SET_BILLING_ADDRESS, 
  ERR_TYPE_GET_BRAINTREE_TOKEN, 
  ERR_TYPE_SET_NONCE_TOKEN, 
  ERR_TYPE_PLACE_ORDER,
} from "../redux/types";
//import BraintreeForm from '../Braintree/BraintreeForm';
import { Braintree, BraintreeHostedField } from "../Braintree/index";
import OrderSummary from '../Components/OrderSummary';

var _ = require('lodash');

function PayView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer>
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer>
          <Column width="100%" center='true'>
            <Header6>Footer</Header6>
          </Column>
        </Footer>
      </ContentContainer>
    </ThemeProvider>
  );


  /*return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <Typography>
        <Column width="100%" cross={Axis.cross.center}>
          <Row width="100%" center>
            <Header></Header>
          </Row>
          <Row>
            <MainMenu>
              <Row main={Axis.main.end} width={lt.innerWidth}>
                <Row>
                  <ContactUsMenu></ContactUsMenu>
                </Row>
              </Row>
            </MainMenu>
          </Row>
          <Column>
            <Body></Body>
          </Column>
        </Column>
      </Typography>
    </ThemeProvider>
  );*/
}


/*function Header() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;


  return(
    <Row width={lt.innerWidth} height={128} color={Color.flatTransparent} cross={Axis.cross.center} main={Axis.main.between}>
      <Column center='true' left={8}>
        <LogoContainer href="/">
          <img style={{width:"100%"}} src="/images/logo.png" alt=""  />
        </LogoContainer>
      </Column>
      <Row center='true'>
        <Row center='true'>
          <HeaderButton startIcon={<Phone/>}>1 (855) 997-9701</HeaderButton>
          <Row><Typography><CallBackButton>Call Back</CallBackButton></Typography></Row>
        </Row>
      </Row>
    </Row>
  );
}*/
 


function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();

  const [shipAndBillThenSameValue, setShipAndBillTheSame] = React.useState(true);

  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errType;
  const cart = stateObj.cart;
  const cart_id = stateObj.cart_id;
  //const cartGrandTotal = stateObj.cartGrandTotal;
  const braintreeToken = stateObj.braintreeToken;
  const placedOrder = stateObj.placedOrder;

  let alert = <Row></Row>;
  if (errMessage !== "" && (  
    errType === ERR_TYPE_GET_BRAINTREE_TOKEN || 
    errType === ERR_TYPE_PLACE_ORDER) ) {
    alert = <Row><Alert severity='error' sx={{marginTop:"20px", marginBottom:"10px"}}><AlertTitle>{errType}</AlertTitle>{errMessage}</Alert></Row>;
  }

  let braintreeComp = 
    <Column top={20}>
      <BraintreeForm token={braintreeToken}></BraintreeForm>
    </Column>;

  useEffect(()=>{
    dispatch(resetState({braintreeToken:null, errMessage:"", errType:""}));
    if (customerToken !== "") {
      isTokenValid(customerToken, (result)=>{
        if (result) {
          if (cart && cart.hasOwnProperty('prices')) {
            if (cart.prices.grand_total.value > 0) {
              dispatch(getBraintreeToken(customerToken));  
            } else {
              dispatch(placeOrder(customerToken, cart_id, "", "free"));
            }
          }
        } else {
          dispatch(logOutMagento());
        }
      });
    }
    /*return(()=>{
      dispatch(resetState({errMessage:"", errType:""}));
    });*/
  },[cart_id, customerToken]);

  let leftPanelWidth = lt.bunch12;
  if (lt.max === 12) {
    leftPanelWidth = lt.bunch8;
  } else if (lt.max == 8) {
    leftPanelWidth = lt.bunch4;
  }

  const continueShoppingHandler = () => {
    navigate("/");
  }

  let content = 
  <>
  <Column width={leftPanelWidth} color={Color.flatTransparent} cross={Axis.cross.start}>
    <Column left={20} right={20}>
      { braintreeToken ? 
      <>
        <Typography variant='h3'>Payment</Typography>
        { braintreeComp }
      </>
      : 
      <Row cross={Axis.cross.center}>
        <Typography variant='h3'>Payment</Typography>
        <Row width={20}></Row>
        <CircularProgress></CircularProgress>
      </Row>
      }
    </Column>
  </Column>
  <Column width={lt.bunch4} color={Color.flatTransparent} bottom={20} cross={Axis.cross.start}>
    <Column top={lt.max === 4 ? 30: 0} bottom={lt.max === 4 ? 40: 0}>
      <OrderSummary cart={cart} width={lt.bunch4} left={lt.max === 4 ? 10: 0} right={lt.max === 4 ? 10: 0}></OrderSummary>
    </Column>
    
  </Column>
  </>
  

  return(
    <Column width="100%" center='true'>
      <div style={{display: lt.max > 4 ? 'block': 'none'}}>
        <Row width={lt.innerWidth} top={60} main={Axis.main.between} >
          { Object.keys(placedOrder).length > 0 ?
          <Column left={20} right={20}>
            <Typography variant='h3'>Thank you for your purchase!</Typography>
            <Row top={30}>
              <Typography variant='body1'>Your order number is: {placedOrder.order.order_number}.<br/>We'll email you an order confirmation with details and tracking info.</Typography>
            </Row>
            <Row top={40}>
              <Button onClick={continueShoppingHandler}>Continue Shopping</Button>
            </Row>
          </Column>
            :
          content
          }
        </Row>
        {alert}
      </div>
      <div style={{display: lt.max === 4 ? 'block': 'none'}}>
        <Column width={lt.innerWidth} top={30} bottom={40} >
          { Object.keys(placedOrder).length > 0 ?
          <Column left={20} right={20} >
            <Typography variant='h5'>Thank you for your purchase!</Typography>
            <Row top={30}>
              <Typography variant='body1'>Your order number is: {placedOrder.order.order_number}.<br/>We'll email you an order confirmation with details and tracking info.</Typography>
            </Row>
            <Row top={40}>
              <Button onClick={continueShoppingHandler}>Continue Shopping</Button>
            </Row>
          </Column>
            :
          content
          }
        </Column>
        {alert}
      </div>
    </Column>
  );
}

const BraintreeForm = (props) => {
  const [tokenize, setTokenizeFunc] = React.useState();
  const [cardType, setCardType] = React.useState('');
  const [auth, setAuth] = React.useState(false);
  const [error, setBraintreeError] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [focusedFieldName, setFocusedField] = React.useState('');
  const numberField = React.useRef();
  const cvvField = React.useRef();
  const cardholderNameField = React.useRef();

  //const [nonce, setNonce] = React.useState("");

  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  
  const customerToken = stateObj.customerToken;
  const cart = stateObj.cart;
  const cart_id = stateObj.cart_id;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errType;
  const placedOrder = stateObj.placedOrder;
  const shippingAddress = stateObj.shippingAddress;
  const billingAddress = stateObj.billingAddress;

  //console.log(placedOrder);

  const onAuthorizationSuccess = () => {
    setAuth(true);
    numberField.current.focus();
  };

  const onDataCollectorInstanceReady = (collector) => {
      // DO SOMETHING with Braintree collector as needed
  };

  const handleError = (newError) => {
    setBraintreeError(newError.message || String(newError));
    dispatch(setError(String(newError), ERR_TYPE_PLACE_ORDER));
  };

  const onFieldBlur = (field, event) => setFocusedField('');
  const onFieldFocus = (field, event) => setFocusedField(event.emittedBy);

  const onCardTypeChange = ({ cards }) => {
    if (1 === cards.length) {
      const [card] = cards;

      setCardType(card.type);

      if (card.code && card.code.name) {
        cvvField.current.setPlaceholder(card.code.name);
      } else {
        cvvField.current.setPlaceholder('CVV');
      }
    } else {
        setCardType('');
        cvvField.current.setPlaceholder('CVV');
    }
  };

  const getToken = () => {
    /*tokenize()
      .then(setToken)
      .catch(handleError);*/

    tokenize().then((token)=>{
      setToken(token);
      dispatch(placeOrder(customerToken, cart_id, token.nonce, "braintree"));
      //dispatch(placeOrder(customerToken, cart_id, token.nonce, "free"));
    }).catch(handleError);
  };

  const renderResult = (title, obj) => {
    /*if (!obj) { return null; }
    return (
      <div>
        <b>{title}:</b>
        <pre>{JSON.stringify(obj, null, 4)}</pre>
      </div>
    );*/
    return null
  };

  return (
    <>
    { auth ? <div></div>: <Column width="100%" height={150} center='true'></Column>}
    <div style={{display: auth ? 'block': 'none'}}>
        <Braintree
        className="demo"
        authorization={props.token}
        onAuthorizationSuccess={onAuthorizationSuccess}
        onDataCollectorInstanceReady={onDataCollectorInstanceReady}
        onError={handleError}
        onCardTypeChange={onCardTypeChange}
        getTokenRef={ref => setTokenizeFunc(() => ref)}
        styles={{
            input: {
                'font-size': 'inherit',
            },
            ':focus': {
                color: '#333',
            },
        }}
      >
        {renderResult('Error', error)}
        {renderResult('Token', token)}

        <Column>
          <BraintreeHostedField
              type="number"
              className={'number' === focusedFieldName ? 'focused' : ''}
              onBlur={onFieldBlur}
              onFocus={onFieldFocus}
              placeholder="4111 1111 1111 1111"
              ref={numberField}
          />
          <FormSubTitle>Card type: {cardType}</FormSubTitle>
          <Row top={10}>
            <BraintreeHostedField
                type="cardholderName"
                className={'cardholderName' === focusedFieldName ? 'focused' : ''}
                onBlur={onFieldBlur}
                onFocus={onFieldFocus}
                placeholder="Name on Card"
                ref={cardholderNameField}
            />
          </Row>
          <Row top={10}>
            <BraintreeHostedField
                type="expirationDate"
                onBlur={onFieldBlur}
                onFocus={onFieldFocus}
                placeholder="mm/yyyy"
                className={'expirationDate' === focusedFieldName ? 'focused' : ''}
            />
          </Row>
          <Row top={10}>
            <BraintreeHostedField 
              type="cvv" 
              className={'cvv' === focusedFieldName ? 'focused' : ''}
              onBlur={onFieldBlur}
              onFocus={onFieldFocus}
              placeholder="CVV" 
              ref={cvvField} 
            />
          </Row>
          <Row top={10}>
            <BraintreeHostedField 
              type="postalCode" 
              className={'postalCode' === focusedFieldName ? 'focused' : ''}
              onBlur={onFieldBlur}
              onFocus={onFieldFocus}
              placeholder="Zip code" 
            />
          </Row>
        </Column>

        </Braintree>
        <Row width='100%'>
          <Row top={20} bottom={20} width="100%"><Button size='large' variant='contained' sx={{width:"100%", color:"#fff"}} onClick={getToken}>Place order</Button></Row>
        </Row>
    </div>
    </>
  );
};







export default PayView16;