import React, {useState, useEffect} from 'react';
import ContactView16 from './View16/Contact16';


function ContactPage() {
  return (
    <ContactView16 />
  );
}

export default ContactPage;