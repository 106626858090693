import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from "@emotion/styled/macro";
import { Card, CardContent, CardMedia, Divider, Paper, Typography } from '@mui/material';
import { ArrowForwardIos, Phone } from '@mui/icons-material';

import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import { getCategoryInfo } from "../redux/actions";
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 
import { cargo } from 'async';

var _ = require('lodash');

function CategoryView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav></MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}
 
const Title = styled(Header1)`
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .8px;
`;

const DecoLine = styled.div`
  transition: width 0.2s linear;
  width: 0px;
  height: 2px;
  background-color: #eec0c0;
`;

const CardWrapper = styled(Card)`
  cursor:pointer;
  &:hover {
    color:${Color.mainColor};
    ${DecoLine} {
      width:30px;
      transition: width 0.2s linear;
    }
  }
`;

const BreadcrumbButton = styled(Button)`
  color:#333;
  font-weight:400;
  padding-top:0px;
  padding-bottom:0px;
  font-size:14px;
  &:hover {
    color:${Color.mainColor};
  }
`;

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const categoryInfo = stateObj.categoryInfo;

  const query = new URLSearchParams(window.location.search);
  const uid = query.get('uid');


  useEffect(()=>{
    //if (_.isEmpty(categoryInfo)) {
      dispatch(getCategoryInfo(uid));
    //}
  },[uid]);

  /*if (customerToken !== "") {
    if (errMessage === "") {
      navigate(`/`);
    }
  }*/

  

  let cat = {...categoryInfo};
  if (_.isEmpty(cat)) {
    cat = {name:"", uid:"", children_count:0, children:[], breadcrumbs:[]};
  } else {
    const i = _.findIndex(cat.children, (o)=>{ return o.name.includes("VIEW ALL") });
    if (i !== -1) {
      const el = {...cat.children[i]};
      //console.log(el);
      cat.children.splice(i,1);
      cat.children.unshift(el);
    }
  }
  if (!cat.breadcrumbs) {
    cat.breadcrumbs = [];
  }

  console.log(cat);

  const homeHandler = ()=>{
    navigate("/");
  }

  const categoryHandler = (event)=>{
    const uid = event.target.dataset.uid;
    //console.log(uid);
    navigate(`/category/?uid=${uid}`);
  }

  const cardHandler = (event) => {
    const obj = JSON.parse(event.currentTarget.dataset.data);
    //console.log(obj);
    if (obj.product_count > 0) {
      navigate(`/productslist/?uid=${obj.uid}&page=1`);
    } else {
      navigate(`/category/?uid=${obj.uid}`);
    }
  }

  let widthCard = (lt.bunch16-20)/5;
  if (lt.max === 12) {
    widthCard = (lt.bunch12-20)/4;
  } else if (lt.max === 8) {
    widthCard = (lt.bunch8-20)/3;
  } else if (lt.max === 4) {
    widthCard = (lt.bunch4-20)/2;
  }
 
  //console.log(cat);

  return(
    <Column width="100%" center>
      <Column width={lt.innerWidth} top={40} cross={Axis.cross.center} >
        <Row left={ lt.max === 4 ? 10 : 20} bottom={30} self={Axis.self.start}>
          <Row><BreadcrumbButton endIcon={<ArrowForwardIos/>} onClick={homeHandler}>Home</BreadcrumbButton></Row>
          { cat.breadcrumbs.map((v,i)=>{
            return(
              <Row key={v.category_uid}><BreadcrumbButton endIcon={<ArrowForwardIos/>} data-uid={v.category_uid} onClick={categoryHandler}>{v.category_name}</BreadcrumbButton></Row>
            );  
          })
          }
          <Row><BreadcrumbButton>{cat.name}</BreadcrumbButton></Row>
        </Row>
        <Container left={0} >
          { cat.children.map((v,i)=>{
            return(
              <Column key={v.uid} width={widthCard} top={10} bottom={10} data-data={JSON.stringify(v)} onClick={cardHandler}>
                <CardWrapper sx={{marginLeft:"20px", marginRight:"0px"}}>
                  <CardMedia
                    component="img"
                    height={widthCard*0.7}
                    image={v.image}
                    alt={v.name}
                    sx={{objectFit:"contain"}}
                  />
                  <CardContent>
                    <Row bottom={10}><Title>{v.name}</Title></Row>
                    <DecoLine></DecoLine>
                  </CardContent>
                </CardWrapper>
              </Column>
            );
          })}
        </Container>
        <div style={{display: lt.max === 4 ? 'block': 'none'}}>
          <Column left={10} right={10} top={80} bottom={100}>
            <Paper elevation={0}>
              <div style={{columnCount: 1, columnGap:0}}>
                <Body2 sx={{paddingBottom:"30px"}}>Dear valued customers, JR Castings is one of the leading casting company in New York City.</Body2>
                <Body2 sx={{paddingBottom:"30px"}}>It is our mission to provide exceptional quality service in producing, custom designing and supplying castings for retail stores as well as wholesale jewelry companies.</Body2>
                <Body2 sx={{paddingBottom:"30px"}}>Our company has recently relocated production lines from overseas to New York City, to provide you precise, efficient and quick service. Our next-day-shipping service is now in full swing to give you the benefit of fast, time and money-saving business, catering to both you and your customers’ needs in this tough period of the economy. In our web page, you will find an extensive variety of items to fit your customers’ needs, which includes traditional and contemporary pieces for any occasion.</Body2>
                <Body2 sx={{paddingBottom:"30px"}}>With more than 20 years of experience in this craft we understand our clients’ special needs and are always open to any suggestions to serve you better. We would like a chance to meet you and have you experience our service.</Body2>
              </div>
            </Paper>
          </Column>
        </div>
        <div style={{display: lt.max > 4 ? 'block': 'none'}}>
          <Column left={20} right={20} top={80} bottom={100}>
            <Paper elevation={0}>
              <div style={{columnCount: 3, columnGap:40}}>
                <Body2 sx={{paddingBottom:"30px"}}>Dear valued customers, JR Castings is one of the leading casting company in New York City.</Body2>
                <Body2 sx={{paddingBottom:"30px"}}>It is our mission to provide exceptional quality service in producing, custom designing and supplying castings for retail stores as well as wholesale jewelry companies.</Body2>
                <Body2 sx={{paddingBottom:"30px"}}>Our company has recently relocated production lines from overseas to New York City, to provide you precise, efficient and quick service. Our next-day-shipping service is now in full swing to give you the benefit of fast, time and money-saving business, catering to both you and your customers’ needs in this tough period of the economy. In our web page, you will find an extensive variety of items to fit your customers’ needs, which includes traditional and contemporary pieces for any occasion.</Body2>
                <Body2 sx={{paddingBottom:"30px"}}>With more than 20 years of experience in this craft we understand our clients’ special needs and are always open to any suggestions to serve you better. We would like a chance to meet you and have you experience our service.</Body2>
              </div>
            </Paper>
          </Column>
        </div>
      </Column>
    </Column>
  );
}


const Container = styled.div`
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:flex-start;
  margin-left:${props=>props.left}px;
  align-self:flex-start;
  ${props => props.debug && `
    border-style:dashed;
    border-width:1px;
    border-color:black;`
  }
`;






export default CategoryView16;