import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { Divider, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle } from './Components';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import { Body1, Body2, Header3, Header4, Header5, Subtitle1, A } from '../Components/TypographyComponents';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function AboutView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer>
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const theme = stateObj.theme;

  let colWidth = lt.bunch8-20;
  if (lt.max === 12) {
    colWidth = lt.bunch6;
  } else if (lt.max === 8) {
    colWidth = lt.bunch8;
  } else if (lt.max === 4) {
    colWidth = lt.bunch4;
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" >
      HOME
    </Link>,
    <Typography key="2" color="text.primary">
      About Us
    </Typography>,
  ];

  return(
    <Column width="100%" center >
      <div style={{display: lt.max >= 12 && lt.max <= 16 ? 'block': 'none'}}>
        <Column width={lt.innerWidth} top={40} bottom={40} >
          <Row left={40}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Row>
          <Row left={40} top={40}>
            <Header4 sx={{fontWeight:600, letterSpacing:3}}>ABOUT US</Header4>
          </Row>
          <Row width="100%" main={Axis.main.center} top={30}>
            <Column width={colWidth} >
              <Column left={20} >
                <Body1>
                  Dear valued customers, JR Castings is one of the leading casting company in New York City.
                </Body1>
                <Body1 sx={{paddingTop:2}}>
                  It is our mission to provide exceptional quality service in producing, custom designing and supplying castings for retail stores as well as wholesale jewelry companies.
                </Body1>
                <Body1 sx={{paddingTop:2}}>
                  Our company has recently relocated production lines from overseas to New York City, to provide you precise, efficient and quick service. Our next-day-shipping service is now in full swing to give you the benefit of fast, time and money-saving business, catering to both you and your customers’ needs in this tough period of the economy.
                </Body1>
                <Body1 sx={{paddingTop:2}}>
                  In our web page, you will find an extensive variety of items to fit your customers’ needs, which includes traditional and contemporary pieces for any occasion.
                </Body1>
                <Body1 sx={{paddingTop:2}}>
                  With more than 20 years of experience in this craft we understand our clients’ special needs and are always open to any suggestions to serve you better. We would like a chance to meet you and have you experience our service.
                </Body1>
                <Row main={Axis.main.between} cross={Axis.cross.center} top={40}>
                  <img src="/images/about1.jpg" style={{verticalAlign:"middle"}} ></img>
                  <Column >
                    <Header5>
                      JR Castings & Findings, Inc.
                    </Header5>
                    <Subtitle1>
                      “Our craft is love from heart to heart”
                    </Subtitle1>
                  </Column>
                </Row>
              </Column>
            </Column>
            <Column left={lt.gutter} width={colWidth} >
              <Row main={Axis.main.center} >
                <Row width={colWidth/2} cross={Axis.cross.start} main={Axis.main.center} >
                  <img src="/images/about2.jpg" style={{width:"95%", height:"95%", objectFit:"contain"}}></img>
                </Row>
                <Row width={colWidth/2} cross={Axis.cross.start} main={Axis.main.center} >
                  <img src="/images/about3.jpg" style={{width:"95%", height:"95%", objectFit:"contain"}}></img>
                </Row>
              </Row>
              <Row main={Axis.main.center} top={15}>
                <Row width={colWidth/2} cross={Axis.cross.start} main={Axis.main.center} >
                  <img src="/images/about4.jpg" style={{width:"95%", height:"95%", objectFit:"contain"}}></img>
                </Row>
                <Row width={colWidth/2} cross={Axis.cross.start} main={Axis.main.center} >
                  <img src="/images/about5.jpg" style={{width:"95%", height:"95%", objectFit:"contain"}}></img>
                </Row>
              </Row>
            </Column>
          </Row>
        </Column>
      </div>
      <div style={{display: lt.max >= 4 && lt.max <= 8 ? 'block': 'none'}}>
        <Column top={30} bottom={20} width={colWidth-20} >
          <Row left={0}><Subtitle1><A href='/' style={{ color:theme.palette.text.main}}>HOME</A> &gt; About us</Subtitle1></Row>
          <Row left={0} bottom={20} top={30}>
            <Header4>ABOUT US</Header4>
          </Row>
          <Body1>
            Dear valued customers, JR Castings is one of the leading casting company in New York City.
          </Body1>
          <Body1 sx={{paddingTop:2}}>
            It is our mission to provide exceptional quality service in producing, custom designing and supplying castings for retail stores as well as wholesale jewelry companies.
          </Body1>
          <Body1 sx={{paddingTop:2}}>
            Our company has recently relocated production lines from overseas to New York City, to provide you precise, efficient and quick service. Our next-day-shipping service is now in full swing to give you the benefit of fast, time and money-saving business, catering to both you and your customers’ needs in this tough period of the economy.
          </Body1>
          <Body1 sx={{paddingTop:2}}>
            In our web page, you will find an extensive variety of items to fit your customers’ needs, which includes traditional and contemporary pieces for any occasion.
          </Body1>
          <Body1 sx={{paddingTop:2}}>
            With more than 20 years of experience in this craft we understand our clients’ special needs and are always open to any suggestions to serve you better. We would like a chance to meet you and have you experience our service.
          </Body1>
          <div style={{display: lt.max === 4 ? 'block': 'none'}}>
            <Column main={Axis.main.between} cross={Axis.cross.center} top={40}>
              <Row bottom={15}>
                <img src="/images/about1.jpg" style={{verticalAlign:"middle"}} ></img>
              </Row>
              <Column >
                <Header5>
                  JR Castings & Findings, Inc.
                </Header5>
                <Subtitle1>
                  “Our craft is love from heart to heart”
                </Subtitle1>
              </Column>
            </Column>
          </div>
          <div style={{display: lt.max === 8 ? 'block': 'none'}}>
            <Row cross={Axis.cross.center} top={40}>
              <Row bottom={15}>
                <img src="/images/about1.jpg" style={{verticalAlign:"middle"}} ></img>
              </Row>
              <Column left={30}>
                <Header5>
                  JR Castings & Findings, Inc.
                </Header5>
                <Subtitle1>
                  “Our craft is love from heart to heart”
                </Subtitle1>
              </Column>
            </Row>
          </div>
          <Column top={40}>
            <Row main={Axis.main.center} >
              <Row width={colWidth} cross={Axis.cross.start} main={Axis.main.center} >
                <img src="/images/about2.jpg" style={{width:"95%", height:"95%", objectFit:"contain"}}></img>
              </Row>
              <Row width={colWidth} cross={Axis.cross.start} main={Axis.main.center} >
                <img src="/images/about3.jpg" style={{width:"95%", height:"95%", objectFit:"contain"}}></img>
              </Row>
            </Row>
            <Row main={Axis.main.center} top={15}>
              <Row width={colWidth} cross={Axis.cross.start} main={Axis.main.center} >
                <img src="/images/about4.jpg" style={{width:"95%", height:"95%", objectFit:"contain"}}></img>
              </Row>
              <Row width={colWidth} cross={Axis.cross.start} main={Axis.main.center} >
                <img src="/images/about5.jpg" style={{width:"95%", height:"95%", objectFit:"contain"}}></img>
              </Row>
            </Row>
          </Column>
        </Column>
      </div>
    </Column>
  );
}





export default AboutView16;