import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from "@emotion/styled/macro";
import { Card, CardContent, CardMedia, Divider, Paper, Typography } from '@mui/material';
import { ArrowForwardIos, Phone } from '@mui/icons-material';

import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProductsList, getCategoryInfo, resetState } from "../redux/actions";
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 
import Pagination from '@mui/material/Pagination';

var _ = require('lodash');

function ProductsListView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav></MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}
 
const Title = styled(Header1)`
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .8px;
`;

const DecoLine = styled.div`
  transition: width 0.2s linear;
  width: 0px;
  height: 2px;
  background-color: #eec0c0;
`;

const CardWrapper = styled(Card)`
  cursor:pointer;
  &:hover {
    color:${Color.mainColor};
    ${DecoLine} {
      width:30px;
      transition: width 0.2s linear;
    }
  }
`;

const BreadcrumbButton = styled(Button)`
  color:#333;
  font-weight:400;
  padding-top:0px;
  padding-bottom:0px;
  font-size:14px;
  &:hover {
    color:${Color.mainColor};
  }
`;

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const productsList = stateObj.productsList;
  const categoryInfo = stateObj.categoryInfo;

  const query = new URLSearchParams(window.location.search);
  const uid = decodeURI(query.get('uid'));
  let queryPage = parseInt(decodeURI(query.get('page')));
  
  const [page, setPage] = React.useState(queryPage);

  const [searchParams, setSearchParams] = useSearchParams();

  

  useEffect(()=>{
    if (!queryPage) {
      queryPage = 1;
      setSearchParams({uid:uid, page: queryPage});
    }
    dispatch(getProductsList(uid, queryPage));
  }, [queryPage]);

  //JSON.stringify(productsList), uid, queryPage

  let totalPages = 0;
  if (productsList.hasOwnProperty('page_info')) {
    if (productsList.page_info.hasOwnProperty('total_pages')) {
      totalPages = productsList.page_info.total_pages;
    }
  }

  let prod = {...productsList};
  if (_.isEmpty(prod)) {
    prod = {items:[]};
  } 

  let cat = {};
  if (_.isEmpty(productsList)) {
    cat = {name:"", uid:"", breadcrumbs:[]};
  } else {
    cat = {...productsList.category_info};
  }
  if (!cat.breadcrumbs) {
    cat.breadcrumbs = [];
  }

  //console.log(productsList);
  //console.log(categoryInfo);

  const handleChangePage = (event, value) => {
    setPage(parseInt(value));
    setSearchParams({uid:uid, page: value});
    dispatch(getProductsList(uid, parseInt(value)));
  };

  const homeHandler = ()=>{
    navigate("/");
  }

  const categoryHandler = (event)=>{
    const uid = event.target.dataset.uid;
    console.log(uid);
    navigate(`/category/?uid=${uid}`);
  }
  const productHandler = (event)=>{
    let sku = event.currentTarget.dataset.sku;
    console.log(sku);
    navigate(`/product/?sku=${sku}`);
  }

  let widthCard = (lt.bunch16-20)/5;
  if (lt.max === 12) {
    widthCard = (lt.bunch12-20)/4;
  } else if (lt.max === 8) {
    widthCard = (lt.bunch8-20)/3;
  } else if (lt.max === 4) {
    widthCard = (lt.bunch4-10)/2;
  }

  //console.log(cat);

  return(
    <Column width="100%" center>
      <div style={{display: lt.max >= 8 && lt.max <= 16 ? 'block': 'none'}}>
        <Column width={lt.innerWidth} top={40} bottom={40} cross={Axis.cross.center} >
          <Row left={20} bottom={30} self={Axis.self.start}>
            <Row><BreadcrumbButton endIcon={<ArrowForwardIos/>} onClick={homeHandler}>Home</BreadcrumbButton></Row>
            { cat.breadcrumbs.map((v,i)=>{
              return(
                <Row key={v.category_uid}><BreadcrumbButton endIcon={<ArrowForwardIos/>} data-uid={v.category_uid} onClick={categoryHandler}>{v.category_name}</BreadcrumbButton></Row>
              );  
            })
            }
            <Row><BreadcrumbButton>{cat.name}</BreadcrumbButton></Row>
          </Row>
          <Container left={0} >
            { prod.items.map((v,i)=>{
              return(
                <Column key={v.uid} width={widthCard} top={10} bottom={10} data-sku={v.sku} onClick={productHandler}>
                  <CardWrapper sx={{marginLeft:"20px", marginRight:"0px"}} >
                    <CardMedia
                      component="img"
                      height={widthCard*0.7}
                      image={v.image.url}
                      alt={v.image.label}
                      sx={{objectFit:"contain"}}
                    />
                    <CardContent>
                      <Row bottom={10}><Title>{v.name}</Title></Row>
                      <DecoLine></DecoLine>
                    </CardContent>
                  </CardWrapper>
                </Column>
              );
            })}
          </Container>
          { totalPages > 1 &&
            <Row top={40}><Pagination count={totalPages} page={queryPage} onChange={handleChangePage} /></Row>
          }
        </Column>
      </div>
      <div style={{display: lt.max === 4 ? 'block': 'none'}}>
        <Column width={lt.innerWidth} top={40} bottom={40} cross={Axis.cross.center} >
          <Container left={0} >
            { prod.items.map((v,i)=>{
              return(
                <Column key={v.uid} width={widthCard} top={10} bottom={10} data-sku={v.sku} onClick={productHandler}>
                  <CardWrapper sx={{marginLeft:"10px", marginRight:"0px"}} >
                    <CardMedia
                      component="img"
                      height={widthCard*0.7}
                      image={v.image.url}
                      alt={v.image.label}
                      sx={{objectFit:"contain"}}
                    />
                    <CardContent>
                      <Row bottom={10}><Title>{v.name}</Title></Row>
                      <DecoLine></DecoLine>
                    </CardContent>
                  </CardWrapper>
                </Column>
              );
            })}
          </Container>
          { totalPages > 1 &&
            <Row top={40}><Pagination count={totalPages} page={queryPage} onChange={handleChangePage} /></Row>
          }
        </Column>
      </div>
    </Column>
  );
}


const Container = styled.div`
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:flex-start;
  margin-left:${props=>props.left}px;
  align-self:flex-start;
  ${props => props.debug && `
    border-style:dashed;
    border-width:1px;
    border-color:black;`
  }
`;






export default ProductsListView16;