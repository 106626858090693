import React, {useState, useEffect} from 'react';
import MobiMenuView16 from './View16/MobilMenu16';


function MobiMenuPage() {
  return (
    <MobiMenuView16 />
  );
}

export default MobiMenuPage;