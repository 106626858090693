import React, {useState, useEffect} from 'react';
import CategoryView16 from './View16/Category16';


function CategoryPage() {
  return (
    <CategoryView16 />
  );
}

export default CategoryPage;