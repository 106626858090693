import React, {useState, useEffect} from 'react';
import ProductView16 from './View16/Product16';


function ProductPage() {
  return (
    <ProductView16 />
  );
}

export default ProductPage;